export const Flex = ({
  children,
  justify = "space-between",
  aligin,
  ...props
}) => (
  <div
    className="flex-generic"
    style={{
      ...styles.flex,
      justifyContent: justify,
      alignItems: aligin,
      ...props,
    }}
  >
    {children}
  </div>
);

const styles = {
  flex: {
    display: "flex",
  },
};
