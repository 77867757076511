import { serverEndpoints } from "./api";
import { CrudService } from "./crud.service";
import { withHeaders } from "./helper.service";

const url = serverEndpoints.portfolioBlock;

const crud = CrudService(serverEndpoints.portfolio);

export const fetchPortfolioItems = crud.fetchItems;
export const fetchPortfolioItem = crud.fetchItem;
export const createPortfolioItem = crud.createItem;
export const updatePortfolioItem = crud.updateItem;
export const deletePortfolioItem = crud.deleteItem;

export const fetchPortfolioInfoBlock = () =>
  fetch(url.index, {
    method: "GET",
    headers: withHeaders(),
  }).then((res) => res.json());
// .then((json) => json.content.portfolioBlock);

export const updatePortfolioInfoBlock = (data) =>
  fetch(url.update, {
    method: "POST",
    headers: withHeaders(),
    body: JSON.stringify({ content: data }),
  }).then((res) => res.json());
