import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { ScreenHeader } from "../components/ScreenHeader";
import { Button } from "@mui/material";
import { Save, Image } from "@mui/icons-material";
import { useModal } from "../../../context/useModal";
import { FileManagerModal } from "../FileManager/modals/FileManagerModal";
import { useState } from "react";

import * as service from "../services/omnie.service";
import { buttons } from "../lang/admin.strings";

export const EditOmnieScreen = ({ location: { state } }) => {
  const history = useHistory();
  const modal = useModal();

  const [imageId, setImageId] = useState(state.image_id);
  const [descriptionPL, setDescriptionPL] = useState(state.description_pl);
  const [descriptionENG, setDescriptionENG] = useState(state.description_eng);

  const submitData = () => {
    service
      .updateOmnieInfo({
        image_id: imageId,
        description_pl: descriptionPL,
        description_eng: descriptionENG,
      })
      .then((_) => history.goBack());
  };

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "O mnie", "Edytować"]}
        />

        <div>
          <div style={styles.flexContainer}>
            <Button
              variant="outlined"
              startIcon={<Image />}
              onClick={() =>
                modal.show(
                  <FileManagerModal
                    onImage={(data) => {
                      setImageId(data.id);
                      modal.hide();
                    }}
                  />
                )
              }
            >
              {buttons.select}
            </Button>
            <div style={styles.flexContainer}>
              <textarea
                style={styles.textarea}
                onChange={({ target: { value } }) => setDescriptionPL(value)}
                value={descriptionPL}
                placeholder="About you"
              />
              <textarea
                style={styles.textarea}
                onChange={({ target: { value } }) => setDescriptionENG(value)}
                value={descriptionENG}
                placeholder="About you"
              />
            </div>
          </div>
          <div style={styles.buttonContainer}>
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={submitData}
            >
              {buttons.save}
            </Button>
          </div>
        </div>
      </>
    </AdminLayout>
  );
};

const styles = {
  textarea: {
    width: 510,
    height: 310,
    padding: 8,
  },
  buttonContainer: { marginTop: 12 },
  flexContainer: {
    display: "flex",
    justifyItems: "center",
    alignContent: "space-around",
  },
};
