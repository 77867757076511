import { Link, useLocation } from "react-router-dom";

export const NavItem = ({ name, link }) => {
  let location = useLocation();
  return (
    <Link className={location.pathname === link ? "activ" : ""} to={link}>
      {name}
    </Link>
  );
};
