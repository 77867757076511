import { useState, useEffect } from "react";
import { AdminLayout } from "../../components/AdminLayout";
import { ScreenHeader } from "../../components/ScreenHeader";
import { CircularProgressIndicator } from "../../components/CircularProgressIndicator";
import { Image } from "../ui";
import { Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as service from "../services/image.service";
import { useModal } from "../../../../context/useModal";
import { ViewImageModal } from "../modals/ViewImageModal";

const lang = {
  uploadBtn: "Prześlij obrazy",
  uploadingInProgressBtn: "Przesyłanie obrazów ...",
};

const utils = {
  removeById: (arr, id) => arr.filter((item) => item.id !== id),
};

export const FileManagerScreen = () => {
  const [images, setImages] = useState(false);
  const [uploading, setUploading] = useState(false);

  const modal = useModal();

  const loadImages = () =>
    service.fetchImages().then((data) => setImages(data.images));
  useEffect(() => {
    loadImages();
  }, []);

  const handleFileFieldChange = async ({ target: { files } }) => {
    try {
      setUploading(true);
      await service.uploadImagesInParalel(files);
    } catch (error) {
    } finally {
      setUploading(false);
    }
  };

  const handleFileDelete = async (id) => {
    try {
      console.log(id);
      await service.deleteImage(id);
      setImages(utils.removeById(images, id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileEdit = (id) => null;

  const onView = (image) => modal.show(<ViewImageModal {...image} />);

  const renderImages = () =>
    images.map((image) => (
      <Image
        deletable
        onClick={() => onView(image)}
        onEdit={handleFileEdit}
        onDelete={handleFileDelete}
        key={image.id}
        {...image}
      />
    ));

  return (
    <AdminLayout>
      <ScreenHeader breadcrumbs={["Menedżer plików"]} />
      <div className="fm_uploadContainer">
        <Button variant="outlined" onClick={() => loadImages()}>
          <RefreshIcon />
        </Button>
        <div style={{ width: 15 }} />
        <Button
          disabled={uploading}
          variant="outlined"
          component="label"
          startIcon={<Save />}
        >
          {uploading ? lang.uploadingInProgressBtn : lang.uploadBtn}
          <input
            hidden
            accept="image/*"
            type="file"
            name="image"
            multiple
            onChange={handleFileFieldChange}
          />
        </Button>
      </div>
      <div>
        <div className="fileManagerModalImagesGrid">
          {images ? renderImages() : <CircularProgressIndicator />}
        </div>
      </div>
    </AdminLayout>
  );
};
