import { useState } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import { Save, Image, AddLink } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { url } from "../../../../app/endpoints";
import { useModal } from "../../../../context/useModal";
import { FileManagerModal } from "../../FileManager/modals/FileManagerModal";
import styled from "@emotion/styled";
import { buttons } from "../../lang/admin.strings";

const lang = {
  save: "Zapisać",
  chooseImage: "Wybierz obraz",
  messages: {
    success: "Wystawy utworzone pomyślnie.",
    fail: "Coś poszło nie tak na serwerze.",
  },
};

const DEFAULTS = {
  title_pl: null,
  title_eng: null,
  description_pl: null,
  description_eng: null,
  image: null,
  link: null,
};

export const XFormExtended = ({ data = DEFAULTS, onSubmit }) => {
  const { register, handleSubmit } = useForm();

  const modal = useModal();
  const [cover, setCover] = useState(data.image);

  const updateCover = (image) => {
    setCover(image);
    modal.hide();
  };

  const prepareForm = (data) =>
    onSubmit({
      ...data,
      image_id: cover?.id,
    });

  return (
    <form onSubmit={handleSubmit(prepareForm)}>
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł PL"
          label="Tytuł PL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">PL</InputAdornment>
            ),
          }}
          {...register("title_pl", { value: data.title_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł ENG"
          label="Tytuł ENG"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">ENG</InputAdornment>
            ),
          }}
          {...register("title_eng", { value: data.title_eng })}
        />
      </FlexRow>
      <br />
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis PL"
          label="Opis PL"
          multiline
          rows={8}
          {...register("description_pl", { value: data.description_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis ENG"
          label="Opis ENG"
          multiline
          rows={8}
          {...register("description_eng", { value: data.description_eng })}
        />
      </FlexRow>
      <br />
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Link do produktu"
          label="Link do produktu"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddLink />
              </InputAdornment>
            ),
          }}
          {...register("link", { value: data.link })}
        />
      </FlexRow>
      <br />
      <br />
      <FlexRow>
        {cover ? (
          <>
            <ImageCard
              image={cover}
              width={320}
              onClick={(_) =>
                modal.show(<FileManagerModal onImage={updateCover} />)
              }
            />
            <HorizontalSpacer />
          </>
        ) : null}

        <div>
          <Button
            variant="outlined"
            startIcon={<Image />}
            onClick={(_) =>
              modal.show(<FileManagerModal onImage={updateCover} />)
            }
          >
            {lang.chooseImage}
          </Button>
        </div>
      </FlexRow>
      <br />
      <br />
      <Button type="submit" variant="contained" startIcon={<Save />}>
        {lang.save}
      </Button>
    </form>
  );
};

const FlexRow = styled.div`
  display: flex;
`;

const HorizontalSpacer = styled.div`
  width: 50px;
`;

const ErrorMessage = styled.span`
  color: #cc0000;
`;

const ImageCard = ({ image, deletable, onClick, onRemove, width }) => {
  return (
    <div>
      <div
        onClick={() => onClick(image)}
        style={{
          width,
          border: "1px solid #2d2d2d",
          borderRadius: 4,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "100%" }} src={url(image.src)} alt={image.alt} />
      </div>
      {deletable && (
        <div>
          <button onClick={() => onRemove(image)}>{buttons.delete}</button>
        </div>
      )}
    </div>
  );
};
