import { TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { authenticateUser } from "../../../app/helpers";
import { useAuth } from "../../../context/useAuth";
import { privateRoutes } from "../../../routes";
import "./style.css";
import { Logo } from "../../../components/header/Logo";

export const LoginScreen = () => {
  const history = useHistory();
  const auth = useAuth();

  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const handleFieldChange = ({ target: { name, value } }) =>
    setFields({
      ...fields,
      [name]: value,
    });

  useEffect(() => {
    // reset errors
    setErrors({
      email: false,
      password: false,
    });
  }, [fields]);

  const signInHandler = () => {
    authenticateUser(fields)
      .then((isAuthenticated) => {
        // here we shoud change status to authenticated in provider
        // and redirect to the /dashboard
        auth.authenticate(isAuthenticated);
        isAuthenticated && history.push(privateRoutes.dashboard);
      })
      .catch((err) => {
        setErrors({
          ...errors,
          ...err,
        });
      });
  };

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="login-header">
         <Logo />
        </div>
        <div>
          <div className="input-field-row">
            <TextField
              label="Email"
              variant="outlined"
              sx={{ width: "100%" }}
              name="email"
              value={fields.email}
              onChange={handleFieldChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </div>
          <div className="input-field-row">
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              sx={{ width: "100%" }}
              name="password"
              value={fields.password}
              onChange={handleFieldChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </div>
        </div>
        <div>
          <Button
            sx={{ width: "100%" }}
            variant="outlined"
            onClick={signInHandler}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
};
