import { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";
import { Sprzedaz } from "../components/shared/Sprzedaz";
import { useLanguage } from "../context/useLanguage";

import * as service from "../services/sprzedaz.service";

export const SprzedazScreen = () => {
  const [products, setProducts] = useState([]);
  const { language, isEnglish } = useLanguage();
  useEffect(() => {
    service.fetchsprzedaz().then((items) => setProducts(items));
  }, [language]);

  const eng = isEnglish();
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <h1 className="display-on">{eng ? "Works" : "Sprzedaż"}</h1>
          <div className="x-grid">
            {products.map((prod, idx) => (
              <Sprzedaz
                key={idx}
                {...prod}
                onClick={() => window.open(prod.link).focus()}
              />
            ))}
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
