import { useState } from "react";
import { Modal } from "../components/shared/Modal";
import { ModalContext } from "./ModalContext";

export const ModalProvider = ({ children }) => {
  const [isShow, setShow] = useState(false);
  const [content, setContent] = useState(null);

  const modal = {
    show(modalContent) {
      setShow(true);
      setContent(modalContent);
    },
    hide() {
      setShow(false);
      setContent(null);
    },
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
      }}
    >
      {children}
      {isShow && <Modal>{content}</Modal>}
    </ModalContext.Provider>
  );
};
