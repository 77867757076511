import { url } from "../../../../app/endpoints";

export const ViewImageModal = (image) => {
  console.log(image);
  return (
    <div className="fvm_flexContainer">
      <div>
        <img style={{ maxWidth: "55vw" }} src={url(image.src)} />
      </div>
      <div style={{ width: 25 }} />
      <div>
        <p>
          <b>Name: </b>
          {image.name}
        </p>
        <p>
          <b>Alternative text: </b>
          {image.alt}
        </p>
        <p>
          <b>URI: </b>
          {url(image.src)}
        </p>
        <p>
          <b>Created at: </b>
          {image.created_at}
        </p>
        <p>
          <b>Updated at: </b>
          {image.updated_at}
        </p>
      </div>
    </div>
  );
};
