import {
  withBearerHeader,
  withJsonHeader,
  buildFormData,
  fetchList,
} from "./helper.service";

export const CrudService = (url) => ({
  fetchItems: () => fetchList(url.read()),

  fetchItem: (id) => fetchList(url.read(id)),

  createItem: (fields) =>
    fetch(url.create, {
      method: "POST",
      headers: withBearerHeader(),
      body: buildFormData(fields),
    }).then((res) => res.json()),

  updateItem: (id, fields) =>
    fetch(url.update, {
      method: "POST",
      headers: withBearerHeader(),
      body: buildFormData({ id, ...fields }),
    }).then((res) => res.json()),

  deleteItem: (id) =>
    fetch(url.delete, {
      method: "DELETE",
      headers: {
        ...withBearerHeader(),
        ...withJsonHeader(),
      },
      body: JSON.stringify({ id }),
    }).then((res) => res.json()),
});
