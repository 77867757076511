import React from "react";
import { Card } from "./Card";
import "./css/card.css";

const GalleryCard = (props) => <Card {...props} />;

GalleryCard.Image = ({ src, alt = "none" }) => (
  <div className="card-image-container">
    <img className="card-image" src={src} alt={alt} />
  </div>
);

export { GalleryCard };
