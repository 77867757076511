import Footer from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { Layout } from "../../components/shared/Layout";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import logoIcon from "../../Images/IconLogo/logo.png";
import { ContactForm } from "./ContactForm";
import { useEffect, useState } from "react";
import * as service from "../../services/contact.service";

import "./css/contact.css";
import { isEnglish } from "../../services/helpers/service-helper";

const logoSize = 150;

const logoProps = {
  width: logoSize,
  height: logoSize,
};

const ContactLogoFigure = () => (
  <figure className="contact-logo">
    <Link to={routes.home}>
      <img src={logoIcon} alt="Logo ISI" {...logoProps} />
    </Link>
  </figure>
);

const PublicInformation = ({ info }) =>
  info.map((item, idx) => (
    <p key={idx} className="space-padding">
      {item}
    </p>
  ));

const firmPublicInformation = ({ nazwa, ulica, miasto, nip }) => [
  nazwa,
  ulica,
  miasto,
  nip,
];

const translate = (pl, eng) => (isEnglish() ? eng : pl);

export const ContactScreen = () => {
  const [info, setInfo] = useState({
    email: "",
    miasto: "",
    nazwa: "",
    nip: "",
    tel: "",
    ulica: "",
  });

  useEffect(() => {
    service.fetchContactInfo().then((data) => setInfo(data));
  }, []);
  const eng = isEnglish();
  return (
    <>
      <Layout>
        <Header />
        <section className="contact-section">
          <h1 className="display-on">{eng ? "Contacts" : "Kontakt"}</h1>

          <div className="container-contact">
            <div className="contact-leftside">
              <ContactLogoFigure />
              <p className="space-padding">
                {translate("Dane firmowe:", "Company Details:")}{" "}
              </p>
              <PublicInformation info={firmPublicInformation(info)} />
              <p className="space-padding">
                {translate(
                  "Zapraszam do zakupu moich prac:",
                  "I invite you to purchase my work:"
                )}
              </p>
              <p className="space-padding">
                {translate("Prace gotowe", "Finished works")}
              </p>
              <p className="space-padding">
                {translate(
                  "Prace dedykowane, na zamówienie: proszę o kontakt do mnie.",
                  "Dedicated, custom-made work: please contact me."
                )}
              </p>
              <h3 className="space-padding">
                {translate("Skontaktuj się ze mną", "Contact us")}
              </h3>
              <p className="space-padding">
                {translate("tel:", "phone:")} {info.tel}
              </p>
              <p className="space-padding">e-mail: {info.email}</p>
            </div>
            <ContactForm />
          </div>
        </section>
      </Layout>
      <Footer />
    </>
  );
};
