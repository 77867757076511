import React from "react";
import { Card } from "./Card";
import "./css/card.css";

const WystawyCard = (props) => <Card {...props} />;

WystawyCard.Image = ({ src, alt = "none" }) => (
  <div className="card-image-container">
    <img width={250} height={250} className="card-image" src={src} alt={alt} />
  </div>
);

export { WystawyCard };
