import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { CircularProgressIndicator } from "../components/CircularProgressIndicator";
import { AdminLayout } from "../components/AdminLayout";
import { ScreenHeader } from "../components/ScreenHeader";
import { privateRoutes } from "../../../routes";
import { useHistory } from "react-router-dom";
import {
  deleteWystawyItem,
  fetchWystawyItems,
} from "../services/wystawy.service";
import { url } from "../../../app/endpoints";
import { buttons } from "../lang/admin.strings";

export const WystawyScreen = () => {
  const history = useHistory();

  const [table, setTable] = useState(null);

  useEffect(() => {
    fetchWystawyItems()
      .then((data) => {
        setTable(data.items);
      })
      .catch((err) => console.log(err));
  }, []);

  const deleteItem = (id) => {
    deleteWystawyItem(id)
      .then((_) => table.filter((element) => element.id !== id))
      .then((data) => setTable(data));
  };

  const renderTable = (rows) => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Obraz</TableCell>
            <TableCell align="right">Tytuł PL</TableCell>
            <TableCell align="right">Tytuł ENG</TableCell>
            <TableCell align="right">Opis PL</TableCell>
            <TableCell align="right">Opis ENG</TableCell>
            <TableCell align="right">Akcje</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right">
                <img
                  src={url(row.image?.src || "NO-IMAGE")}
                  alt={row.image?.alt || "NO-IMAGE"}
                  width={45}
                />
              </TableCell>
              <TableCell align="right">{row.title_pl}</TableCell>
              <TableCell align="right">{row.title_eng}</TableCell>
              <TableCell align="right">{row.description_pl}</TableCell>
              <TableCell align="right">{row.description_eng}</TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  onClick={() => history.push(privateRoutes.wystawy.edit, row)}
                >
                  {buttons.edit}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => deleteItem(row.id)}
                >
                  {buttons.delete}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <AdminLayout>
      <div>
        <ScreenHeader
          breadcrumbs={["Panel CMS", "Wystawy"]}
          actions={
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={() => history.push(privateRoutes.wystawy.add)}
            >
              Dodaj nową wystawę
            </Button>
          }
        />
        {table ? renderTable(table) : <CircularProgressIndicator />}
      </div>
    </AdminLayout>
  );
};
