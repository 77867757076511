import { routes, privateRoutes } from "./routes";
import {
  ContactScreen,
  HomeScreen,
  KatalogPrac,
  OmnieScreen,
  PolicyEN,
  PolicyPL,
  PortfolioScreen,
  SprzedazScreen,
  WystawyScreen,
} from "./screens";
import * as AdmDashboard from "./screens/admin/Dashboard";
import * as AdmPortfolio from "./screens/admin/Portfolio";
import * as AdmWystawy from "./screens/admin/Wystawy";
import * as AdmKatalogPrac from "./screens/admin/KatalogPrac";
import * as AdmSprzedaz from "./screens/admin/Sprzedaz";
import * as AdmOmnie from "./screens/admin/Omnie";
import * as AdmKontakt from "./screens/admin/Kontakt";
import * as AdmFileManager from "./screens/admin/FileManager";

const publicRoutesMap = [
  {
    path: routes.home,
    component: HomeScreen,
  },
  {
    path: routes.portfolio,
    component: PortfolioScreen,
  },
  {
    path: routes.wystawy,
    component: WystawyScreen,
  },
  {
    path: routes.katalogprac,
    component: KatalogPrac,
  },
  {
    path: routes.sprzedaz,
    component: SprzedazScreen,
  },
  {
    path: routes.omnie,
    component: OmnieScreen,
  },
  {
    path: routes.kontakt,
    component: ContactScreen,
  },
  {
    path: routes.polityka,
    component: PolicyPL,
  },
  {
    path: routes.policy,
    component: PolicyEN,
  },
];
const privateRoutesMap = [
  /* Dashboard */
  {
    path: privateRoutes.dashboard,
    component: AdmDashboard.Dashboard,
    isPrivate: true,
  },
  {
    path: privateRoutes.addNewSlider,
    component: AdmDashboard.AddNewSlider,
    isPrivate: true,
  },

  /* Portfolio */
  {
    path: privateRoutes.portfolio.list,
    component: AdmPortfolio.PortfolioScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.portfolio.add,
    component: AdmPortfolio.CreatePortfolioScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.portfolio.edit,
    component: AdmPortfolio.EditPortfolioScreen,
    isPrivate: true,
  },

  /* Wystawy */
  {
    path: privateRoutes.wystawy.list,
    component: AdmWystawy.WystawyScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.wystawy.add,
    component: AdmWystawy.CreateWystawyScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.wystawy.edit,
    component: AdmWystawy.EditWystawyScreen,
    isPrivate: true,
  },

  /* Katalog Prac */
  {
    path: privateRoutes.katalogPrac.list,
    component: AdmKatalogPrac.KatalogPracScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.katalogPrac.add,
    component: AdmKatalogPrac.CreateKatalogPracScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.katalogPrac.edit,
    component: AdmKatalogPrac.EditKatalogPracScreen,
    isPrivate: true,
  },

  /* Sprzedaz */
  {
    path: privateRoutes.sprzedaz.list,
    component: AdmSprzedaz.SprzedazScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.sprzedaz.add,
    component: AdmSprzedaz.CreateSprzedazScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.sprzedaz.edit,
    component: AdmSprzedaz.EditSprzedazScreen,
    isPrivate: true,
  },

  /* O mnie */
  {
    path: privateRoutes.omnie.list,
    component: AdmOmnie.OmnieScreen,
    isPrivate: true,
  },
  {
    path: privateRoutes.omnie.edit,
    component: AdmOmnie.EditOmnieScreen,
    isPrivate: true,
  },

  /* O mnie */
  {
    path: privateRoutes.kontakt.index,
    component: AdmKontakt.KontaktScreen,
    isPrivate: true,
  },

  /* File Manager */
  {
    path: privateRoutes.fileManager.index,
    component: AdmFileManager.FileManagerScreen,
    isPrivate: true,
  },
];

export const routesMap = [...publicRoutesMap, ...privateRoutesMap];
