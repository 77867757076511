import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import "./css/modal.css";
import { CloseIconButton } from "./Icons";

export const Modal = ({ children }) => {
  const { modal } = useContext(ModalContext);

  return (
    <div className="modal">
      <div
        onClick={({ target }) => {
          if (target.className === "overlay") {
            modal.hide();
          }
        }}
        className="overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <CloseIconButton onClick={modal.hide} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
