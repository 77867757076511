import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XFormExtended } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/sprzedaz.service";

export const CreateSprzedazScreen = () => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "Sprzedaz", "Stwórz"]}
        />

        <XFormExtended
          onSubmit={(data) => {
            service.createSprzedazItem(data).then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
