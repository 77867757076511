import { server } from "../../../app/endpoints";

// generate CRUD endpoints
const generateCrudEndpoints = (prefix) => ({
  read: (id) =>
    id
      ? `${server}/api/v1/server/${prefix}/${id}`
      : `${server}/api/v1/server/${prefix}`,
  create: `${server}/api/v1/server/${prefix}/create`,
  update: `${server}/api/v1/server/${prefix}/update`,
  delete: `${server}/api/v1/server/${prefix}/delete`,
});

const uri = (...parts) => `${server}/api/v1/server/${parts.join("/")}`;

export const serverEndpoints = {
  slider: generateCrudEndpoints("slider"),
  portfolio: generateCrudEndpoints("portfolio"),
  portfolioBlock: {
    index: uri("home", "portfolio"),
    update: uri("home", "portfolio"),
  },
  wystawy: generateCrudEndpoints("wystawy"),
  katalogPrac: generateCrudEndpoints("katalog-prac"),
  sprzedaz: generateCrudEndpoints("sprzedaz"),
  omnie: generateCrudEndpoints("o-mnie"),
  kontakt: generateCrudEndpoints("kontakt"),
  gallery: {
    index: uri("home", "gallery"),
    update: uri("home", "gallery"),
  },
  latestWorks: {
    index: uri("home", "latest-works"),
    update: uri("home", "latest-works"),
  },
  socialLinks: {
    index: uri("home", "social-links"),
    update: uri("home", "social-links"),
  },
  seo: {
    get: (name) => `${server}/server/seo/${name}`,
    update: uri("seo", "update"),
  },
};
