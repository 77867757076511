import { serverEndpoints } from "./api";
import { CrudService } from "./crud.service";

const crud = CrudService(serverEndpoints.katalogPrac);

export const fetchKatalogPracItems = crud.fetchItems;
export const fetchKatalogPracItem = crud.fetchItem;
export const createKatalogPracItem = crud.createItem;
export const updateKatalogPracItem = crud.updateItem;
export const deleteKatalogPracItem = crud.deleteItem;
