import { routes } from "../../routes";
import { isEnglish } from "../../services/helpers/service-helper";
import { NavItem } from "./NavItem";
import { SocialIconItem } from "./SocialIconItem";

export const Navigations = () => {
  const eng = isEnglish();
  return (
    <div className="container">
      <div className="nav-container">
        <NavItem
          name={eng ? "Portfolio" : "Portfolio"}
          link={routes.portfolio}
        />
        <NavItem name={eng ? "Exhibitions" : "Wystawy"} link={routes.wystawy} />
        <NavItem
          name={eng ? "Catalog" : "Katalog prac"}
          link={routes.katalogprac}
        />
        <NavItem name={eng ? "Works" : "Sprzedaż"} link={routes.sprzedaz} />
        <NavItem name={eng ? "About me" : "O mnie"} link={routes.omnie} />
        <NavItem name={eng ? "Contacts" : "Kontakt"} link={routes.kontakt} />
        <div className="social">
          <SocialIconItem />
        </div>
      </div>
    </div>
  );
};
