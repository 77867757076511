import GalleryModalSlider from "../components/slider/GalleryModalSlider";
import { Flex } from "../components/ui";

export const GalleryModal = ({ images }) => {
  return (
    <Flex aligin="center" style={styles.imageContainer}>
      <GalleryModalSlider sliderImage={images} />
    </Flex>
  );
};

const styles = {
  imageContainer: {
    marginRight: "22px",
  },
};
