import { useContext, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";
import { ProductCard } from "../components/shared/ProductCard";
import { KatalogModalContainer } from "../containers/KatalogModalContainer";
import { ModalContext } from "../context/ModalContext";
import { useLanguage } from "../context/useLanguage";

import * as service from "../services/katalog-prac.service";

export const KatalogPrac = () => {
  const { modal } = useContext(ModalContext);
  const { language, isEnglish } = useLanguage();

  const [products, setProducts] = useState([]);

  const _fetch = () => {
    service.fetchKatalogPrac().then((items) => setProducts(items));
  };

  useEffect(() => {
    _fetch();
  }, []);

  useEffect(() => {
    _fetch();
  }, [language]);

  const eng = isEnglish();
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <h1 className="display-on">{eng ? "Catalog" : "Katalog Prac"}</h1>

          <div className="x-grid">
            {products.map((prod, idx) => (
              <ProductCard
                key={idx}
                {...prod}
                onClick={() => modal.show(<KatalogModalContainer {...prod} />)}
              />
            ))}
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
