import { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { Save } from "@mui/icons-material";
import { AdminLayout } from "../components/AdminLayout";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/kontakt.service";

export const KontaktScreen = () => {
  const [info, setInfo] = useState({});

  useEffect(() => {
    service.fetchKontaktInfo().then((info) => setInfo(info));
  }, []);

  const handleInput = ({ target: { name, value } }) =>
    setInfo({
      ...info,
      [name]: value,
    });

  return (
    <AdminLayout>
      <div>
        <ScreenHeader
          breadcrumbs={["Panel CMS", "Kontakt"]}
          actions={
            <Button
              variant="outlined"
              startIcon={<Save />}
              onClick={() =>
                service.updateKontaktInfo({ info: JSON.stringify(info) })
              }
            >
              Zaktualizuj kontakt
            </Button>
          }
        />
        <div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="Nazwa Firmy"
              name="nazwa"
              value={info.nazwa || ""}
              onChange={handleInput}
            />
          </div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="Ulica"
              name="ulica"
              value={info.ulica || ""}
              onChange={handleInput}
            />
          </div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="Miasto"
              name="miasto"
              value={info.miasto || ""}
              onChange={handleInput}
            />
          </div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="NIP"
              name="nip"
              value={info.nip || ""}
              onChange={handleInput}
            />
          </div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="Telefon"
              name="tel"
              value={info.tel || ""}
              onChange={handleInput}
            />
          </div>
          <div style={styles.textFieldRow}>
            <TextField
              sx={{ width: "100%" }}
              label="E-mail"
              name="email"
              value={info.email || ""}
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

const styles = {
  textFieldRow: { margin: "18px 0" },
};
