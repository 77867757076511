import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Dashboard,
  Collections,
  Inventory2TwoTone,
  AspectRatio,
  ContactMail,
  Info,
  AttachMoney,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { privateRoutes as route } from "../../../routes";

const pages = [
  {
    icon: <Dashboard />,
    page: "Strona Główna",
    url: route.dashboard,
  },
  {
    icon: <AspectRatio />,
    page: "Portfolio",
    url: route.portfolio.list,
  },
  {
    icon: <AspectRatio />,
    page: "Wystawy",
    url: route.wystawy.list,
  },
  {
    icon: <Inventory2TwoTone />,
    page: "Katalog prac",
    url: route.katalogPrac.list,
  },
  {
    icon: <AttachMoney />,
    page: "Sprzedaz",
    url: route.sprzedaz.list,
  },
  {
    icon: <Info />,
    page: "O mnie",
    url: route.omnie.list,
  },
  {
    icon: <ContactMail />,
    page: "Kontakt",
    url: route.kontakt.index,
  },
  {
    icon: <Collections />,
    page: "Menedżer plików",
    url: route.fileManager.index,
  },
];

export const Navigation = () => {
  const history = useHistory();
  return (
    <Box>
      <List sx={{ width: "100%" }} component="nav">
        {pages.map((item) => (
          <ListItemButton
            key={item.page}
            onClick={() => {
              history.push(item.url);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.page} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
