import React from "react";
import { url } from "../../app/endpoints";
import "./css/card.css";

const Card = ({ children, border, ...props }) => (
  <div
    style={{
      border: border ? "1px solid #ccc" : "none",
    }}
    className="card-container"
    {...props}
  >
    {children}
  </div>
);

Card.Image = ({ src, alt = "none" }) => (
  <div className="card-image-container">
    <img className="card-image" src={url(src)} alt={alt} />
  </div>
);

export { Card };
