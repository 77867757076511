import React from "react";
import { url } from "../../app/endpoints";
import { isEnglish } from "../../services/helpers/service-helper";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img
            className="slide-image"
            src={url(slide?.image.src)}
            alt={slide?.image.alt}
          />
          <div className="text-br">
            <h2 className="slide-title">
              {isEnglish() ? slide.title_eng : slide.title_pl}
            </h2>
            <h3 className="slide-text">
              {isEnglish() ? slide.description_eng : slide.description_pl}
            </h3>
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
