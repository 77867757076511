import { useContext, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";
import { PortfolioCard } from "../components/shared/PortfolioCard";
import { PortfolioModalContainer } from "../containers";
import { ModalContext } from "../context/ModalContext";
import { useLanguage } from "../context/useLanguage";
import * as service from "../services/portfolio.service";

export const PortfolioScreen = () => {
  const { modal } = useContext(ModalContext);
  const { language, isEnglish } = useLanguage();
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    service.fetchPortfolio().then((items) => setPortfolio(items));
  }, [language]);

  const eng = isEnglish();
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <h1 className="display-on">{eng ? "Portfolio" : "Portfolio"}</h1>
          <div className="x-grid">
            {portfolio.map((prod, idx) => (
              <PortfolioCard
                key={idx}
                {...prod}
                onClick={() =>
                  modal.show(<PortfolioModalContainer {...prod} />)
                }
              />
            ))}
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
