import { serverEndpoints } from "./api";
import { CrudService } from "./crud.service";

const crud = CrudService(serverEndpoints.wystawy);

export const fetchWystawyItems = crud.fetchItems;
export const fetchWystawyItem = crud.fetchItem;
export const createWystawyItem = crud.createItem;
export const updateWystawyItem = crud.updateItem;
export const deleteWystawyItem = crud.deleteItem;
