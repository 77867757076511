import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/useAuth";

export const PrivateRoute = (props) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/adm" />;
  }

  return <Route {...props} />;
};
