import { Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { destroyUserSession } from "../../../app/helpers";
import { Logo } from "../../../components/header/Logo";
import { routes } from "../../../routes";
import { Navigation } from "./Navigation";

import "./style.css";

export const AdminLayout = ({ children }) => {
  const history = useHistory();

  const signOutHandler = () => {
    destroyUserSession().then((_) => history.push(routes.home));
  };

  return (
    <div className="adm-fluid-container">
      <div className="flex full-height">
        <div className="adm-sidebar">
          <div className="adm-header-logo">
            <Typography variant="h5">
              <div className="center">
                <Logo />
              </div>
              <div className="space-padding">Panel CMS</div>
            </Typography>
          </div>
          <Navigation />
        </div>
        <div className="adm-box">
          <div className="adm-header">
            <div>
              <Button variant="outlined" onClick={signOutHandler}>
                Wyloguj
              </Button>
            </div>
          </div>
          <div className="adm-page-content">{children}</div>
        </div>
      </div>
    </div>
  );
};
