import { serverEndpoints } from "./api";
import { withHeaders } from "./helper.service";

const uri = serverEndpoints.slider;

export const fetchSlider = () =>
  fetch(uri.read(), {
    method: "GET",
    headers: withHeaders(),
  }).then((res) => res.json());

export const createSlider = (fields) =>
  fetch(uri.create, {
    method: "POST",
    headers: withHeaders(),
    body: JSON.stringify(fields),
  });

export const deleteSlider = (id) =>
  fetch(uri.delete, {
    method: "DELETE",
    headers: withHeaders(),
    body: JSON.stringify({ id }),
  });
