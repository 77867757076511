import React, { useEffect, useState } from "react";
import Arrows from "./Arrows";
import sliderImage from "./sliderImage";
import "./slider.css";
import GalleryModalContent from "./GalleryModalContent";

function GalleryModalSlider({ sliderImage }) {
  const len = sliderImage.length - 1;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="slider-container">
      <GalleryModalContent
        activeIndex={activeIndex}
        sliderImage={sliderImage}
      />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
    </div>
  );
}

export default GalleryModalSlider;
