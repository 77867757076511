import React from "react";
import { url } from "../../app/endpoints";
import { Button } from "./Button";
import { GalleryCard } from "./GalleryCard";

export const ProductCard = ({ image, title, description, onClick }) => (
  <GalleryCard onClick={onClick} border>
    <GalleryCard.Image src={url(image.src)} />
    <div>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
    <div className="card-buttons-area">
      <Button title="Więcej" onClick={onClick} />
    </div>
  </GalleryCard>
);
