import { useEffect, useState } from "react";

export const useRequest = (promise) => {
  const [data, setData] = useState(null);
  const [inProgress, setProgress] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    promise()
      .then((data) => {
        setProgress(false);
        setData(data);
      })
      .catch((errors) => setErrors(errors.message))
      .finally(setProgress(false));
  }, [promise]);

  return { data, inProgress, errors };
};
