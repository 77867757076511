export const buildFormData = (fields) => {
  const formData = new FormData();
  Object.entries(fields).forEach(([name, value]) => {
    formData.append(name, value);
  });

  return formData;
};

export const fetchList = (url) =>
  fetch(url, {
    method: "GET",
    headers: withBearerHeader(),
  }).then((res) => res.json());

export const withBearerHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("_token")}`,
});

export const withJsonHeader = () => ({
  "Content-Type": "application/json",
});

export const withHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("_token")}`,
  "Content-Type": "application/json",
});
