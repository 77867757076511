import { useContext } from "react";
import { ApplicationContext } from "./ApplicationContext";

export const useLanguage = () => {
  const { language, setLanguage } = useContext(ApplicationContext);
  const flags = {
    pl: "pl-PL",
    eng: "en-US",
  };

  return {
    language: language.prefix,
    setLanguage,
    flag: flags,
    isEnglish: () => language.lang !== flags.pl,
  };
};
