import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { routesMap } from "./router";
import { PrivateRoute } from "./app/PrivateRoute";
import { NotFoundScreen } from "./screens";
import { LoginScreen } from "./screens/admin/LoginScreen/LoginScreen";
import { useAuth } from "./context/useAuth";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={`/adm`}
          render={() =>
            isAuthenticated ? <Redirect to="/adm/dashboard" /> : <LoginScreen />
          }
        />
        {routesMap.map((route, idx) =>
          route?.isPrivate ? (
            <PrivateRoute exact key={`${idx}::${route.path}`} {...route} />
          ) : (
            <Route exact key={`${idx}::${route.path}`} {...route} />
          )
        )}
        <Route exact path="/404" component={NotFoundScreen} />
        <Route component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
