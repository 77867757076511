import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XFormExtended } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/sprzedaz.service";

export const EditSprzedazScreen = ({ location: { state } }) => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "Sprzedaz", "Edytuj - " + state.title]}
        />

        <XFormExtended
          data={state}
          onSubmit={(data) => {
            service
              .updateSprzedazItem(state.id, data)
              .then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
