import { useEffect, useState } from "react";
import { url } from "../app/endpoints";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";
import { useLanguage } from "../context/useLanguage";
import * as service from "../services/about-me.service";

export const OmnieScreen = () => {
  const [info, setInfo] = useState({
    image: { src: "" },
    description: [],
  });
  const { language, isEnglish } = useLanguage();

  const _fetch = () => {
    service.fetchOMnieInfo().then((data) => setInfo(data));
  };

  useEffect(() => {
    _fetch();
  }, []);

  useEffect(() => {
    _fetch();
  }, [language]);

  const eng = isEnglish();

  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <h1 className="display-on">{eng ? "About me" : "O mnie"}</h1>
          <div className="flex-gallery">
            <div className="img-container">
              <img src={url(info.image.src)} alt="" />
            </div>
            <div className="gallery-description">
              <div className="o-mnie-description">
                {info.description.map((paragraph, idx) => (
                  <p key={idx}>{paragraph}</p>
                ))}
              </div>
            </div>
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
