import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useRequest } from "../../../../app/helpers/network";
import { fetchSlider, deleteSlider } from "../../services/slider.service";
import { useHistory } from "react-router-dom";
import { privateRoutes } from "../../../../routes";
import { url } from "../../../../app/endpoints";
import styled from "@emotion/styled";
import { buttons } from "../../lang/admin.strings";

const ErrorMessage = styled.span`
  color: #cc0000;
`;

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

const ImageContainer = styled.div``;

export const SliderTab = () => {
  const history = useHistory();
  const { data, errors } = useRequest(fetchSlider);

  const deleteItem = (id) => {
    deleteSlider(id);
    history.push(privateRoutes.dashboard);
  };

  if (errors) {
    return (
      <>
        Oops Something went wrong! reason: <ErrorMessage>{errors}</ErrorMessage>
      </>
    );
  }

  return (
    <Container>
      <Header>
        <Button
          variant="outlined"
          startIcon={<AddCircleOutline />}
          onClick={() => history.push(privateRoutes.addNewSlider)}
        >
          {buttons.create}
        </Button>
      </Header>
      {data?.length ? (
        <SliderTable rows={data} onDelete={deleteItem} />
      ) : (
        <p>Żadnych slajdów, miłego dnia na ich tworzenie :{`)`}</p>
      )}
    </Container>
  );
};

const SliderTable = ({ rows, onDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            <TableCell>Obraz</TableCell>
            <TableCell align="right">Url</TableCell>
            <TableCell align="right">Akcje</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <ImageContainer>
                  <img height={75} src={url(row.image?.src)} alt=""/>
                </ImageContainer>
              </TableCell>
              <TableCell align="right">{row.image?.src}</TableCell>
              <TableCell align="right">
                <Button variant="outlined" onClick={() => onDelete(row.id)}>
                {buttons.delete}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
