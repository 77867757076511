import { useState } from "react";
import { Button } from "../../components/shared/Button";
import { sendContactMessage } from "../../services";
import { isEnglish } from "../../services/helpers/service-helper";
import { InputField } from "./InputField";

const defaultUserDataState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  message: "",
  isAgreed: false,
};

const defaultErrorsState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  message: "",
  isAgreed: "",
};

export const ContactForm = () => {
  const [userData, setUserData] = useState(defaultUserDataState);
  const [errors, setErrors] = useState(defaultErrorsState);
  const [successSent, setSuccessSent] = useState(false);

  const handleInputChange = ({ target: { name, value } }) =>
    setUserData({
      ...userData,
      [name]: value,
    });

  const submitData = async (event) => {
    try {
      event.preventDefault();
      setErrors(defaultErrorsState);
      await sendContactMessage(userData);
      setSuccessSent(true);
    } catch (errors) {
      console.log(errors);
      setErrors(errors);
    }
  };

  return (
    <div className="contact-rightside">
      <form>
        <div className="row-contact">
          {successSent && (
            <div
              style={{
                backgroundColor: "rgb(237, 247, 237)",
                padding: "17px",
                margin: "10px 0",
                borderRadius: "5px",
              }}
            >
              Message sent successfuly.
            </div>
          )}
          <InputField
            name="firstName"
            placeholder="Imię"
            value={userData.firstName}
            onChange={handleInputChange}
            errors={errors.firstName}
          />
          <InputField
            name="lastName"
            placeholder="Nazwiśko"
            value={userData.lastName}
            onChange={handleInputChange}
            errors={errors.lastName}
          />
          <InputField
            name="phone"
            placeholder="Telefon"
            value={userData.phone}
            onChange={handleInputChange}
            errors={errors.phone}
          />
          <InputField
            name="email"
            placeholder="E-mail"
            value={userData.email}
            onChange={handleInputChange}
            errors={errors.email}
          />
          <InputField
            name="address"
            placeholder="Addres"
            value={userData.address}
            onChange={handleInputChange}
            errors={errors.address}
          />
          <div>
            <textarea
              type="text"
              className="textarea form-control"
              style={{ height: 150 }}
              placeholder="Napis coś"
              name="message"
              value={userData.message}
              onChange={handleInputChange}
              errors={errors.message}
            />
          </div>
        </div>
        <div className="buttons-area">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkbox-agreed"
              name="isAgreed"
              value={userData.isAgreed}
              onChange={handleInputChange}
            />
            <label
              htmlFor="checkbox-agreed"
              className="form-check-label main-contact"
            >
              {isEnglish()
                ? "I agree to the processing of personal data"
                : "Wyrażam zgodę na przetwarzanie danych osobowych"}
            </label>
          </div>
        </div>
        <div className="buttons-area">
          <Button
            style={{ width: "100%", borderRadius: 3 }}
            title={isEnglish() ? "Send" : "Wyśli"}
            onClick={submitData}
          />
        </div>
      </form>
    </div>
  );
};
