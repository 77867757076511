import { serverEndpoints } from "./api";
import { withHeaders } from "./helper.service";

const url = serverEndpoints.latestWorks;

export const fetchLatestWorkBlock = () =>
  fetch(url.index, {
    method: "GET",
    headers: withHeaders(),
  })
    .then((res) => res.json())
    .then((json) => json.content.latestWorksBlock);

export const updateLatestWorkBlock = (data) =>
  fetch(url.update, {
    method: "POST",
    headers: withHeaders(),
    body: JSON.stringify({ content: data }),
  }).then((res) => res.json());
