export const IconsNames = {
  close: "fa fa-times",
};

export const FaIcon = ({ name }) => <i className={name} />;

export const IconButton = ({ icon, onClick }) => (
  <div style={styles.iconButton} onClick={onClick}>
    {icon}
  </div>
);

export const CloseIconButton = (props) => (
  <IconButton icon={<FaIcon name={IconsNames.close} />} {...props} />
);

const styles = {
  iconButton: {
    cursor: "pointer",
    fontSize: "24px",
    fontWeight: "normal",
    width: "19px",
  },
};
