import { api } from "../app/endpoints";

export const fetchSliderImages = () =>
  fetch(api.slider)
    .then((res) => res.json())
    .then((data) => data);

export const fetchHomeInfo = () =>
  fetch(api.home)
    .then((res) => res.json())
    .then((data) => data);
