import { serverEndpoints } from "./api";
import { CrudService } from "./crud.service";

const crud = CrudService(serverEndpoints.sprzedaz);

export const fetchSprzedazItems = crud.fetchItems;
export const fetchSprzedazItem = crud.fetchItem;
export const createSprzedazItem = crud.createItem;
export const updateSprzedazItem = crud.updateItem;
export const deleteSprzedazItem = crud.deleteItem;
