import validator from "validator";
import { sendEmail } from "./contact.service";

const validateFields = (fields) => {
  const errors = {};

  for (const [key, value] of Object.entries(fields)) {
    if (key === "isAgreed") {
      continue;
    }

    if (validator.isEmpty(value)) {
      errors[key] = "Field should not be empty.";
    }
  }

  if (!validator.isEmail(fields.email)) {
    errors.email = "Email is wrong.";
  }

  // DEVNOTE: Temporary commented
  // if (fields.isAgread !== true) {
  //   errors.isAgread = "You should accept policy.";
  // }

  if (Object.keys(errors).length) {
    return errors;
  }

  return null;
};

export const sendContactMessage = async (message) => {
  const errors = validateFields(message);

  if (errors) {
    return Promise.reject(errors);
  }

  return sendEmail(message);
};
