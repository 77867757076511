import React from "react";
import { url } from "../../app/endpoints";
import "./css/wystawy.css";
import { WystawyCard } from "./WystawyCard";

export const WystawyImageCard = ({ image, title, onClick }) => (
  <WystawyCard onClick={onClick} border>
    <WystawyCard.Image src={url(image.src)} />
    <div className="wystawy-image-card">
      <p>{title}</p>
    </div>
  </WystawyCard>
);
