import validator from "validator";
import {
  withJsonHeader,
  withBearerHeader,
} from "../../screens/admin/services/helper.service";
import { endpoints } from "../endpoints";
import { AUTH } from "../lang";

export const authenticateUser = async ({ email, password }) => {
  // validate user input
  const errors = {};

  if (!validator.isEmail(email)) {
    errors.email = AUTH.EMAIL_ERROR_MESSAGE;
  }
  if (validator.isEmpty(password)) {
    errors.password = AUTH.PASSWORD_ERROR_MESSAGE;
  }

  if (Object.keys(errors).length) {
    return Promise.reject(errors);
  }

  // send ajax request to server
  const data = JSON.stringify({
    email,
    password, // DEVNOTE: password should be encrypted
  });

  try {
    const response = await fetch(endpoints.auth, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (response.status !== 200) {
      throw new Error(`Something went wrong! status: ${response.status}`);
    }

    /**
     * Response:
     *  {
     *    status: 'OK',
     *    token: @base64 string
     *  }
     */
    const { token } = await response.json();

    // store token in local storage
    localStorage.setItem("_token", token);
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const destroyUserSession = async () => {
  const token = localStorage.getItem("_token");

  if (!token) {
    return Promise.reject(null);
  }

  localStorage.removeItem("_token");

  const data = JSON.stringify({ token });

  try {
    await fetch(endpoints.signOut, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    // DEVNOTE: No need to handle some errors, just alway return true.
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const validateUserSession = async () => {
  const token = localStorage.getItem("_token");

  if (!token) {
    return Promise.reject(false);
  }

  try {
    const res = await fetch(endpoints.validateToken, {
      method: "GET",
      headers: {
        ...withJsonHeader(),
        ...withBearerHeader(),
      },
    });

    const { valide: isValide } = await res.json();

    return Promise.resolve(isValide);
  } catch (error) {
    return Promise.reject(error.message);
  }
};
