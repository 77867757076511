import { serverEndpoints } from "./api";
import { withHeaders } from "./helper.service";

const url = serverEndpoints.socialLinks;

export const fetchSocialLinksBlock = () =>
  fetch(url.index, {
    method: "GET",
    headers: withHeaders(),
  }).then((res) => res.json());

export const updateSocialLinksBlock = (data) =>
  fetch(url.update, {
    method: "POST",
    headers: withHeaders(),
    body: JSON.stringify({ links: data }),
  }).then((res) => res.json());
