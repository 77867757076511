import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";
import { Flex, Spacer } from "../components/ui";
import { routes } from "../routes";

const flexProps = {
  justify: "center",
  aligin: "center",
  flexDirection: "column",
  minHeight: "60vh",
};

export const NotFoundScreen = () => (
  <>
    <Layout>
      <Header />
      <ContentScreenLayout>
        <Flex {...flexProps}>
          <div>
            <h3>Nie możemy znaleźć tej strony</h3>
            <Spacer vertical="25px" />
            <p>
              Jesteśmy prawie pewni, że ta strona kiedyś tu była, ale wygląda na
              to, że zniknęła. Przepraszamy.
            </p>
            <Spacer vertical="25px" />
            <div>
              <Link to={routes.home} style={{ textDecoration: "none" }}>
                Wróć na główną stronę
              </Link>
            </div>
          </div>
        </Flex>
      </ContentScreenLayout>
    </Layout>
    <Footer />
  </>
);
