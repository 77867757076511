import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";

export const PolicyEN = () => {
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <div className="policy-style">
            <p>
              <strong>
                I. <strong>GENERAL PROVISIONS:</strong>
              </strong>
            </p>
            <ol>
              <li>
                The data controller for data collected via the isidesign.pl
                website is ISI Design Iwona Sienkiewicz ul. Makowa 26 c m 33
                55-093 Kiełczów, e-mail address:&nbsp;
                <a
                  title="Send us a message"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
                .
              </li>
              <li>
                If you have any concerns or requests related to your rights,
                please e-mail us{" "}
                <a
                  title="Send us a message"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
                .
              </li>
              <li>
                Your personal data is processed in accordance with the
                provisions of Directive (EU) 2016/679 of the European Parliament
                and of the Council of 27 April 2016 on the protection of natural
                persons with regard to the processing of personal data and on
                the free movement of such data, and repealing
                Directive&nbsp;95/46/EC (General Data Protection Regulation).
              </li>
              <li>
                We take great care to protect the interests of the data
                subjects. In particular, we ensure that the data is:
                <ol>
                  <li>
                    processed lawfully, fairly and in a transparent manner in
                    relation to the data subject,
                  </li>
                  <li>
                    collected for specified, explicit and legitimate purposes
                    and not further processed in a manner that is incompatible
                    with those purposes,
                  </li>
                  <li>
                    adequate, relevant and limited to what is necessary in
                    relation to the purposes for which they are processed,
                  </li>
                  <li>accurate and, where necessary, kept up to date,</li>
                  <li>
                    kept in a form which permits identification of data subjects
                    for no longer than is necessary for the purposes for which
                    the personal data are processed,
                  </li>
                  <li>
                    processed in a manner that ensures appropriate security of
                    the personal data.
                  </li>
                </ol>
              </li>

              <li>
                In order to provide the highest service quality, we use
                technical solutions for website analysis and operation. We do
                not use these tools to process your personal data. Below is a
                list of the tools we use:
                <ol>
                  <li>
                    Google Analytics – an online tool for analyzing website
                    statistics,
                  </li>
                  <li>
                    Google AdWords – an advertising system that makes it
                    possible to display sponsored links in Google search results
                    and on partner sites,
                  </li>
                  <li>
                    Yandex Metrica, Heap Analytics – website analysis tools,
                  </li>
                  <li>
                    Push notifications – notifications that appear on your
                    computer screen in real time,
                  </li>
                  <li>
                    Live chat – a UserEngage service that allows you to chat
                    with us.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <strong>
                II.{" "}
                <strong>
                  &nbsp;PURPOSE, SCOPE AND LEGAL BASIS OF DATA COLLECTION:
                </strong>
              </strong>
            </p>
            <ol>
              <li>
                Your personal data is processed for three purposes, depending on
                your consent:
                <ol>
                  <li>
                    to answer your inquiries submitted through our contact form
                    – the legal basis for such processing is the provision
                    regarding processing which is necessary to take steps at the
                    request of the data subject,
                  </li>
                  <li>
                    to send offers and newsletters – the legal basis for such
                    processing is your consent to the processing of your
                    personal data for one or more specific purposes,
                  </li>
                  <li>
                    for recruitment purposes – the legal basis for such
                    processing is your consent to the processing of your
                    personal data for one or more specific purposes.
                  </li>
                </ol>
              </li>
              <li>
                We process the following types of your data:
                <ol>
                  <li>first and last name,</li>
                  <li>e-mail address,</li>
                  <li>telephone number,</li>
                  <li>
                    if applicable, other types of data included in the inquiry
                    submitted through our contact form or in files submitted to
                    us as e-mail attachments.
                  </li>
                </ol>
              </li>
              <li>
                We do not collect additional information about you from other
                sources.
              </li>
              <li>
                We do not sell personal data or share it with other entities. In
                particular, your data is not transferred outside the European
                Economic Area or shared with international organizations.
              </li>
            </ol>
            <p>
              <strong>
                III. <strong>DATA STORAGE AND DELETION</strong>
              </strong>
            </p>
            <ol>
              <li>
                We take care to minimize the storage period of personal data we
                no longer use. However, data may be stored for as long as
                necessary for operational and technical reasons. This includes
                the time required to decide whether further contact is
                necessary, as well as the rime required to delete backup copies
                of the data.
              </li>
              <li>
                Data processed for the purpose of handling your inquiries will
                be processed by us for the duration of the correspondence
                exchange and subsequently, depending on the outcome of the
                exchange, either added to our client base and processed further
                for the purpose of the performance of a contract or deleted if
                there is no possibility of entering into business relations.
              </li>
              <li>
                If you end the conversation in a way that is clear to us, the
                data will be immediately deleted from our working systems and
                its backup copies will be deleted within 30 days.
              </li>
              <li>
                If you do not reply, leaving no clear indication whether the
                conversation will continue, the data will be stored for 6 months
                counting from the date the last message.
              </li>
              <li>
                Data processed based on consent (i.e. data used to distribute
                marketing information) will be processed until consent is
                withdrawn. The backup copies of the data will be removed within
                an additional 30 days.
              </li>
            </ol>
            <p>
              <strong>
                IV.{" "}
                <strong>
                  &nbsp;RIGHT TO WITHDRAW CONSENT, RIGHT OF CONTROL, ACCESS AND
                  RECTIFICATION BY THE DATA SUBJECT, RIGHT TO DATA PORTABILITY,
                  RIGHT TO OBJECT TO FURTHER PROCESSING AND RIGHT TO LODGE A
                  COMPLAINT WITH A SUPERVISORY AUTHORITY
                </strong>
              </strong>
            </p>
            <ol>
              <li>
                We process your data for marketing purposes based on your freely
                given consent. Such consent may be withdrawn at any time. In
                order to withdraw consent, please send us a message from the
                e-mail address or phone number on which you are receiving
                marketing content. The only consequence of withdrawing your
                consent is no longer being able to receive information about our
                product range and other marketing content. Withdrawing your
                consent does not affect the legality of the data processing
                carried out by us prior to the withdrawal.
              </li>
              <li>
                You have the right to access your personal data and to request
                immediate rectification of inaccurate personal data. In
                addition, you have the right to request supplementation of
                incomplete personal data.
              </li>
              <li>
                At any time, you have the right to object to the processing of
                your personal data for such marketing purposes (including
                profiling) to the extent to which such processing is connected
                with such direct marketing.
              </li>
              <li>
                You have the right to request that we limit the processing of
                your personal data for the time required to verify the accuracy
                of such data or to pursue claims.
              </li>
              <li>
                You also have the right to request that we send your data to you
                or directly to another data controller in a commonly used and
                machine-readable format. This right only concerns data which we
                receive from you. Considering the fact that we only process your
                data in text form, the transfer of data will consist in
                generating text documents in the .docx format containing the
                history of your inquiries. Such documents will not contain our
                responses, in particular our advice or other expert knowledge,
                as you are not entitled to such data (it is not subject to
                transfer).
              </li>
              <li>
                For all of the above matters, please contact us at&nbsp;
                <a
                  title="Send us a message"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
                .
              </li>
              <li>
                You also have the right to lodge a complaint with the
                supervisory authority: the President of the Personal Data
                Protection Office.
              </li>
            </ol>
            <p>
              <strong>
                V. <strong>COOKIES</strong>
              </strong>
            </p>
            <ol>
              <li>
                Entering our website may cause cookies to be saved to your
                device. Cookies are small text files sent out by our server or a
                Google server.
              </li>
              <li>
                Cookies from our server are sent out occasionally. They enable
                the site to remember the usersU+2019 preferences regarding the
                appearance of the pages (e.g. font size), register that a user
                has voted in a poll, as well as make it possible for users to
                complete actions consisting of multiple stages.
              </li>
              <li>
                We also use cookies that make it possible to display ads
                matching the interests of site visitors.
              </li>
              <li>
                We do not store or process the information sent from our server
                and saved on your device.
              </li>
              <li>
                Besides cookies sent from our server, our sites also use Google
                cookies that match ads displayed in Google modules to your
                interests.
              </li>
              <li>
                You can disable saving some or all cookies by changing your
                browser settings.
              </li>
              <li>
                If you decide to reject all cookies, you will not be able to
                access some of the content or services made available through
                our websites, in particular ones that require logging in.
                Disabling cookies does not, however, remove your ability to
                browse pages on our sites.
                <ul className="list-type-6">
                  <li>
                    <a
                      href="https://help.opera.com/pl/latest/web-preferences/#cookies"
                      rel="noopener"
                    >
                      w przeglądarce Opera
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
                      rel="noopener"
                    >
                      w przegladarce Firefox
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                      rel="noopener"
                    >
                      w przeglądarce Chrome
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://windows.microsoft.com/pl-pl/windows7/block-enable-or-allow-cookies"
                      rel="noopener"
                    >
                      w przeglądarce Internet Explorer
                    </a>
                  </li>
                  <li>
                    <a href="http://support.apple.com/kb/PH5042">
                      w przeglądarce Safarii
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                If you are using a mobile device (e.g. phone or tablet), please
                refer to the documentation available on the website of the
                manufacturer of your device in order to learn about privacy
                features.
              </li>
            </ol>
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
