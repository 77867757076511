import { useState, useEffect } from "react";
import { ApplicationContext } from "./ApplicationContext";
import { validateUserSession } from "../app/helpers";

const pl = "pl-PL";
const eng = "en-US";

const getActiveLanguage = () =>
  localStorage.getItem("lang") ||
  window.navigator.userLanguage ||
  window.navigator.language;
const getLanguagePrefix = (lang) => (lang === pl ? "pl" : "eng");

export const ApplicationProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticationStatus] = useState(false);
  const [langFlag, setLangFlag] = useState(getActiveLanguage());

  useEffect(() => {
    const token = localStorage.getItem("_token");
    if (!token) {
      setAuthenticationStatus(false);
      return;
    }

    validateUserSession()
      .then((isValide) => {
        setAuthenticationStatus(isValide);
      })
      .catch(() => setAuthenticationStatus(false));
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        isAuthenticated,
        authenticate: setAuthenticationStatus,
        language: {
          prefix: getLanguagePrefix(langFlag),
          lang: langFlag,
        },
        setLanguage: (lang) => {
          const l = lang === "pl" ? pl : eng;
          localStorage.setItem("lang", l);
          setLangFlag(l);
        },
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
