import { serverEndpoints } from "./api";
import {
  buildFormData,
  withBearerHeader,
  withJsonHeader,
} from "./helper.service";

const url = serverEndpoints.omnie;

export const fetchOmnieInfo = () =>
  fetch(url.read(), {
    method: "GET",
    headers: {
      ...withBearerHeader(),
      ...withJsonHeader,
    },
  }).then((res) => res.json());

export const updateOmnieInfo = (data) =>
  fetch(url.update, {
    method: "POST",
    headers: withBearerHeader(),
    body: buildFormData(data),
  }).then((res) => res.json());
