import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { Layout } from "../components/shared/Layout";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { WystawyImageCard } from "../components/shared/WystawyImageCard";
import { ModalContext } from "../context/ModalContext";
import { useContext, useEffect, useState } from "react";
import { WystawyModalContainer } from "../containers/WystawyModalContainer";
import { Card } from "../components/shared/Card";
import { useLanguage } from "../context/useLanguage";
import * as service from "../services/wystawy.service";

export const WystawyScreen = () => {
  const { modal } = useContext(ModalContext);
  const { language, isEnglish } = useLanguage();
  const [wystawy, setWystawy] = useState([]);
  const [primaryItem, setPrimaryItem] = useState(null);

  useEffect(() => {
    service.fetchWystawy().then((items) => {
      setPrimaryItem(items.shift());
      setWystawy(items);
    });
  }, [language]);

  const eng = isEnglish();
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <h1 className="display-on">{eng ? "Exhibitions" : "Wystawy"}</h1>

          <div className="flex-container">
            <div className="primary-image">
              {wystawy.length ? (
                <Card
                  {...primaryItem}
                  onClick={() =>
                    modal.show(<WystawyModalContainer {...primaryItem} />)
                  }
                >
                  <Card.Image {...primaryItem.image} />
                </Card>
              ) : null}
            </div>
            <div className="wystawy-grid">
              {wystawy.map((item, idx) => (
                <WystawyImageCard
                  key={idx}
                  {...item}
                  onClick={() =>
                    modal.show(<WystawyModalContainer {...item} />)
                  }
                />
              ))}
            </div>
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
