import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { Layout } from "../components/shared/Layout";

export const PolicyPL = () => {
  return (
    <>
      <Layout>
        <Header />
        <ContentScreenLayout>
          <div className="policy-style">
            <h1 className="center">Zasady ochrony prywatności oraz cookies</h1>
            <p>
              <strong>I. POSTANOWIENIA OGÓLNE:</strong>
            </p>
            <ol>
              <li>
                Administratorem danych osobowych zbieranych za pośrednictwem
                strony internetowej www.isidesign.pl jest firma ISI Design Iwona
                Sienkiewicz ul. Makowa 26 c m 33 55-093 Kiełczów, adres poczty
                elektronicznej:
                <a
                  title="Napisz do nas"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
              </li>
              <li>
                Swoje wątpliwości oraz wnioski dotyczące woli skorzystania z
                przysługujących Państwu praw, prosimy kierować na adres{" "}
                <a
                  title="Napisz do nas"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
              </li>
              <li>
                Państwa dane osobowe przetwarzane są zgodnie z postanowieniami
                Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
                dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych).
              </li>
              <li>
                Dokładamy staranności w celu ochrony interesów osób, których
                dane dotyczą, a w szczególności zapewniamy, że zbierane dane są:
                <ol>
                  <li>
                    przetwarzane zgodnie z prawem, rzetelnie i w sposób
                    przejrzysty dla osoby, której dane dotyczą,
                  </li>
                  <li>
                    zbierane w konkretnych, wyraźnych i prawnie uzasadnionych
                    celach i nieprzetwarzane dalej w sposób niezgodny z tymi
                    celami,
                  </li>
                  <li>
                    adekwatne, stosowne oraz ograniczone do tego, co niezbędne
                    do celów, w których są przetwarzane,
                  </li>
                  <li>prawidłowe i w razie potrzeby uaktualniane,</li>
                  <li>
                    przechowywane w formie umożliwiającej identyfikację osoby,
                    której dane dotyczą, przez okres nie dłuższy, niż jest to
                    niezbędne do celów, w których dane te są przetwarzane,
                  </li>
                  <li>
                    przetwarzane w sposób zapewniający odpowiednie
                    bezpieczeństwo danych osobowych.
                  </li>
                </ol>
              </li>
              <li>
                W celu zapewnienia usług na najwyższym poziomie korzystamy z
                narzędzi technicznych związanych z analizą i obsługą strony
                internetowej. Narzędzia te nie służą nam do przetwarzania
                Pani/Pana danych osobowych. Korzystamy z:
                <ol>
                  <li>
                    Google Analytics – internetowe narzędzie do analizy
                    statystyk serwisów WWW,
                  </li>
                  <li>
                    Google AdWords – system reklamowy pozwalający na
                    wyświetlanie linków sponsorowanych w wynikach wyszukiwania
                    wyszukiwarki Google i na stronach współpracujących,
                  </li>
                  <li>
                    Yandex Metrica, Heap Analytics- jest narzędziem służące do
                    analizy strony internetowej
                  </li>
                  <li>
                    Powiadomienia Push - powiadomienia pojawiające się w czasie
                    rzeczywistym na ekranie komputera,
                  </li>
                  <li>
                    Livechat – usługa firmy UserEngage zapewniająca możliwość
                    prowadzenia rozmów z Państwem.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <strong>
                II. CEL I ZAKRES ZBIERANIA DANYCH ORAZ PODSTAWA PRAWNA TYCH
                DZIAŁAŃ:
              </strong>
            </p>
            <ol>
              <li>
                Państwa dane osobowe przetwarzane są w trzech celach, zależnie
                od wyrażonej zgody:
                <ol>
                  <li>
                    udzielania odpowiedzi na Państwa zapytania kierowane przez
                    formularz kontaktowy - podstawą prawną tych działań jest
                    zasada, która mówi, że przetwarzanie danych jest niezbędne
                    do podjęcia działań na żądanie osoby, której dane dotyczą,
                  </li>
                  <li>
                    wysyłania ofert, newslettera - podstawą prawną tych działań
                    jest Państwa zgoda na przetwarzanie danych osobowych w
                    jednym lub większej liczbie określonych celów,
                  </li>
                  <li>
                    do celów rekrutacyjnych - podstawą prawną tych działań jest
                    Państwa zgoda na przetwarzanie danych osobowych w jednym lub
                    większej liczbie określonych celów.
                  </li>
                </ol>
              </li>
              <li>
                Przetwarzamy następujący zakres Państwa danych:
                <ol>
                  <li>Imię i nazwisko,</li>
                  <li>adres e-mail,</li>
                  <li>numer telefonu,</li>
                  <li>
                    ewentualne inne dane podane przez Państwa w treści zapytania
                    przesłanego za pomocą formularza kontaktowego, bądź
                    przesłane nam w plikach załączonych do korespondecncji,
                  </li>
                </ol>
              </li>
              <li>
                Nie zbieramy o Państwu dodatkowych informacji z innych źródeł.
              </li>
              <li>
                Nie sprzedajemy danych osobowych, nie udostępniamy ich innym
                podmiotom. W szczególności Państwa dane nie są przesyłane poza
                granice Europejskiego Obszaru Gospodarczego ani nie są
                przekazywane organizacjom międzynarodowym.
              </li>
            </ol>
            <p>
              <strong>III. PRZECHOWYWANIE I USUWANIE DANYCH </strong>
            </p>
            <ol>
              <li>
                Staramy się maksymalnie ograniczać okres przechowywania danych
                osobowych, które nie są już wykorzystywane. Informujemy jednak,
                że sytuacja taka może mieć miejsce z przyczyn operacyjnych oraz
                technologicznych. Mamy przez to na myśli czas potrzebny na
                podjęcie decyzji o tym, czy dalszy kontakt jest wskazany jak
                również czas potrzebny na usunięcie danych z kopii
                bezpieczeństwa.
              </li>
              <li>
                Państwa dane przetwarzane do celu obsługi zapytania ofertowego
                będą się u nas procesowane przez okres trwania korespondencji, a
                następnie, w zależności od tego, jakie będą jej rezultaty, albo
                zasilą bazę naszych klientów i będą dalej przetwarzane w celu
                realizacji umowy, albo zostaną usunięte jeżeli nie zaistnieje
                możliwość nawiązania współpracy.
              </li>
              <li>
                W przypadku czytelnego dla nas zamknięcia rozmów z Państwa
                strony, dane zostaną usunięte niezwłocznie z roboczych baz
                systemowych, a z kopii bezpieczeństwa przed upływem trzydziestu
                dni.
              </li>
              <li>
                W przypadku braku odpowiedzi i niejasnej sytuacji, dane będą
                przechowywane przez okres 6 miesięcy od daty nadania ostatniej
                wiadomości.
              </li>
              <li>
                Dane przetwarzane na podstawie zgody, a więc dane wykorzystywane
                do wysyłania informacji marketingowych, będą przetwarzane do
                momentu cofnięcia zgody. Należy również doliczyć okres 30 dni
                potrzebny nam na usunięcie ich z kopii bezpieczeństwa.
              </li>
            </ol>
            <p>
              <strong>
                IV. PRAWO DO COFNIĘCIA ZGODY, PRAWO KONTROLI, DOSTĘPU DO TREŚCI
                SWOICH DANYCH ORAZ ICH POPRAWIANIA, PRAWO DO PRZENIESIENIA
                DANYCH, PRAWO DO WNIESIENIA SPRZECIWU WOBEC DALSZEGO
                PRZETWARZANIA ORAZ PRAWO DO WNIESIENIA SKARGI DO ORGANU
                NADZORCZEGO{" "}
              </strong>
            </p>
            <ol>
              <li>
                Zawiadamiamy, że przetwarzamy Państwa dane w celach
                marketingowych na podstawie Państwa dobrowolnej zgody.
                Zawiadamiamy, że zgoda ta może zostać cofnięta w dowolnym
                momencie. Sugerujemy w tym celu przesłać nam wiadomość z tego
                samego adresu e-mail lub numeru telefonu, na który otrzymujecie
                Państwo treści marketingowe. Wyłączną konsekwencją cofnięcia
                zgody będzie fakt, że nie będziecie Państwo mogli dłużej
                otrzymywać od nas informacji o naszej ofercie i innych treści
                marketingowych. Zawiadamiamy również, że cofnięcie zgody nie
                wpływa na legalność przetwarzania przez nas danych, które
                dokonało się przed tym cofnięciem.
              </li>
              <li>
                Przysługuje Państwu prawo dostępu do treści swoich danych
                osobowych oraz prawo żądania niezwłocznego sprostowania danych
                osobowych, które są nieprawidłowe. Ponadto macie Państwo prawo
                żądania uzupełnienia niekompletnych danych osobowych.
              </li>
              <li>
                Przysługuje Państwu prawo do wniesienia w dowolnym momencie
                sprzeciwu wobec przetwarzania Państwa danych osobowych na
                potrzeby takiego marketingu, w tym profilowania, w zakresie, w
                jakim przetwarzanie jest związane z takim marketingiem
                bezpośrednim.
              </li>
              <li>
                Przysługuje Państwu prawo żądania byśmy ograniczyli
                przetwarzanie waszych danych osobowych na czas sprawdzenia
                prawidłowości danych, bądź dochodzenia roszczeń.
              </li>
              <li>
                Przysługuje Państwu również prawo do wezwania nas, abyśmy
                przesłali Państwa dane do Państwa albo bezpośrednio innemu
                administratorowi w powszechnie używanym formacie nadającym się
                do odczytu maszynowego. Zawiadamiamy, że prawo to dotyczy tylko
                tych danych, które od Państwa otrzymaliśmy. Zważywszy na fakt,
                że wyłączne dane na Państwa temat przetwarzane są w postaci
                tekstowej, przeniesienie danych sprowadzi się do wygenerowania
                przez nas dokumentów tekstowych w formacie docx zawierających
                historię Państwa zapytań. Dokumenty te nie będą zawierały
                naszych odpowiedzi, a więc w szczególności naszych porad lub
                innego rodzaju wiedzy eksperckiej ponieważ nie jest ona
                przedmiotem Państwa uprawnienia (nie podlega przeniesieniu).
              </li>
              <li>
                Wszystkie powyższe sprawy prosimy kierować do naszego biura na{" "}
                <a
                  title="Napisz do nas"
                  href="mailto:i.j.sienkiewicz@gmail.com"
                >
                  i.j.sienkiewicz@gmail.com
                </a>
              </li>
              <li>
                Zawiadamiamy, że przysługuje Państwu również prawo do wniesienia
                skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony
                Danych.
              </li>
            </ol>
            <p>
              <strong>V. PLIKI „COOKIES”</strong>
            </p>
            <ol>
              <li>
                Kiedy wchodzisz na strony naszej witryny, na dysku Twojego
                komputera mogą być zapisywane tzw. ciasteczka (ang. cookies),
                czyli niewielkie pliki tekstowe wysyłane przez nasz serwer albo
                serwer Google.
              </li>
              <li>
                Z naszego serwera wysyłamy pliki ciasteczek wyjątkowo. Służą one
                zapamiętaniu preferowanego wyglądu stron, ustawionego przez
                użytkowników, np. rozmiaru czcionki, odnotowaniu faktu
                głosowania w prowadzonych ankietach albo wykonaniu przez
                użytkownika kilkuetapowych czynności.
              </li>
              <li>
                Korzystamy również z ciasteczek służących dopasowaniu
                wyświetlanych reklam do zainteresowań odwiedzających witryny.
              </li>
              <li>
                Zapisywanych na Twoim komputerze informacji wysyłanych z naszego
                serwera nigdzie nie gromadzimy i w żaden sposób nie
                przetwarzamy.
              </li>
              <li>
                Oprócz ciasteczek wysyłanych z naszego serwera za pośrednictwem
                naszych witryn wysyłane są cookies firmy Google, które służą
                dopasowaniu reklam wyświetlanych w modułach Google do Twoich
                zainteresowań.
              </li>
              <li>
                W każdym momencie możesz wyłączyć możliwość zapisywania
                wszystkich lub niektórych ciasteczek poprzez zmianę ustawień w
                swojej przeglądarce internetowej.
              </li>
              <li>
                Jeżeli zdecydujesz się na odrzucenie wszystkich ciasteczek, nie
                będzie możliwe korzystanie z niektórych treści i usług
                udostępnianych w naszych witrynach, w szczególności wymagających
                logowania. Wyłączenie ciasteczek nie powoduje natomiast braku
                możliwości przeglądania stron w naszych witrynach.
                <ul>
                  <li>
                    <a
                      href="https://help.opera.com/pl/latest/web-preferences/#cookies"
                      rel="noopener"
                    >
                      w przeglądarce Opera
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
                      rel="noopener"
                    >
                      w przegladarce Firefox
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                      rel="noopener"
                    >
                      w przeglądarce Chrome
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://windows.microsoft.com/pl-pl/windows7/block-enable-or-allow-cookies"
                      rel="noopener"
                    >
                      w przeglądarce Internet Explorer
                    </a>
                  </li>
                  <li>
                    <a href="http://support.apple.com/kb/PH5042">
                      w przeglądarce Safarii
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Jeśli korzystasz z urządzenia przenośnego (telefon, smartfon,
                tablet), zapoznaj się z opcjami dotyczącymi ochrony prywatności
                w dokumentacji na stronie internetowej producenta Twojego
                urządzenia.
              </li>
            </ol>
          </div>
        </ContentScreenLayout>
      </Layout>
      <Footer />
    </>
  );
};
