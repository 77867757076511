import { TextField, InputAdornment, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useRequest } from "../../../../app/helpers/network";
import {
  fetchSocialLinksBlock,
  updateSocialLinksBlock,
} from "../../services/social-links.service";
import styled from "@emotion/styled";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { buttons } from "../../lang/admin.strings";

const FlexRow = styled.div`
  display: flex;
`;

const ErrorMessage = styled.span`
  color: #cc0000;
`;

export const SocialLinksTab = () => {
  const { data, inProgress, errors } = useRequest(fetchSocialLinksBlock);
  const save = (data) => updateSocialLinksBlock(data);

  if (errors) {
    return (
      <>
        Oops Something went wrong! reason: <ErrorMessage>{errors}</ErrorMessage>
      </>
    );
  }

  return inProgress ? (
    <>ładowanie danych</>
  ) : (
    !!data && <SocialLinksForm data={data} onSubmit={save} />
  );
};

const SocialLinksForm = ({ data, onSubmit }) => {
  const { register, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="linkedin"
          label="linkedin"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkedInIcon />
              </InputAdornment>
            ),
          }}
          {...register("linkedin", { value: data.linkedin })}
        />
      </FlexRow>
      <br />
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="facebook"
          label="facebook"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FacebookIcon />
              </InputAdornment>
            ),
          }}
          {...register("facebook", { value: data.facebook })}
        />
      </FlexRow>
      <br />
      <br />
      <Button type="submit" variant="contained" startIcon={<Save />}>
      {buttons.save}
      </Button>
    </form>
  );
};
