import React from "react";
import { url } from "../../app/endpoints";

function GalleryModalContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img
            className="slide-image-modal"
            src={url(slide.src)}
            alt={slide.alt}
          />
        </div>
      ))}
    </section>
  );
}

export default GalleryModalContent;
