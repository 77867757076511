import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { isEnglish } from "../../services/helpers/service-helper";
import { NavItem } from "../header/NavItem";
import "./footer.css";

const APP_NAME = "ISI. Desing";
export const Footer = () => {
  const props = isEnglish()
    ? {
        name: "Policy",
        link: routes.policy,
      }
    : {
        name: "Polityka prywatności",
        link: routes.polityka,
      };

  return (
    <div className="footer-container">
      <section>
        <div>
          <p>
            &copy;{new Date().getFullYear()} {`${APP_NAME} `}
            <Link to={props.link}>{props.name}</Link>
          </p>
        </div>
      </section>
    </div>
  );
};
export default Footer;
