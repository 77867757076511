import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XForm } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/portfolio.service";

export const EditPortfolioScreen = ({ location: { state } }) => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={[
            "Panel CMS",
            "Portfolio",
            "Edytuję - " + state.title_pl,
          ]}
        />

        <XForm
          data={state}
          onSubmit={(data) => {
            service
              .updatePortfolioItem(state.id, data)
              .then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
