import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XForm } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/wystawy.service";

export const EditWystawyScreen = ({ location: { state } }) => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Dashboard", "Wystawy", "Edit - " + state.title_pl]}
        />

        <XForm
          data={state}
          onSubmit={(data) => {
            service
              .updateWystawyItem(state.id, data)
              .then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
