import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { CircularProgressIndicator } from "../components/CircularProgressIndicator";
import { AdminLayout } from "../components/AdminLayout";
import { ScreenHeader } from "../components/ScreenHeader";
import { privateRoutes } from "../../../routes";
import { useHistory } from "react-router-dom";
import * as service from "../services/omnie.service";
import { url } from "../../../app/endpoints";
import { buttons } from "../lang/admin.strings";

const uri = privateRoutes.omnie;

export const OmnieScreen = () => {
  const history = useHistory();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    service
      .fetchOmnieInfo()
      .then((data) => setInfo(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <AdminLayout>
      <div>
        <ScreenHeader
          breadcrumbs={["Panel CMS", "O mnie"]}
          actions={
            <Button
              variant="outlined"
              startIcon={<EditOutlined />}
              onClick={() => history.push(uri.edit, info)}
            >
              {buttons.edit}
            </Button>
          }
        />
        {info ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
               height: "360px",
            }}
          >
            <img
              style={{
                marginRight: 15,
              }}
              src={url(info.image?.src)}
              alt={info.image?.alt || "NO-IMAGE"}
            />
            <div style={{ width: "100%", display: "flex",
    justifyContent: "flex-end"}}>
      
              <p>{info.description_pl}</p>
              <div
                style={{
                  height: 1,
                  border: "1px dashed #2d2d2d",
                  margin: "8px 0",
                }}
              ></div>
              <p>{info.description_eng}</p>
            </div>
          </div>
        ) : (
          <CircularProgressIndicator />
        )}
      </div>
    </AdminLayout>
  );
};
