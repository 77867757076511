export const routes = {
  home: "/",
  portfolio: "/portfolio",
  wystawy: "/wystawy",
  katalogprac: "/katalog-prac",
  sprzedaz: "/sprzedaz",
  omnie: "/o-mnie",
  kontakt: "/kontakt",
  login: "/adm",
  polityka: "/polityka-prywatnosci",
  policy: "/privacy-and-cookies-policy",
};

export const privateRoutes = {
  dashboard: "/adm/dashboard",
  addNewSlider: "/adm/dashboard/slider-add",
  portfolio: {
    list: "/adm/dashboard/portfolio",
    add: "/adm/dashboard/portfolio/add",
    edit: "/adm/dashboard/portfolio/edit",
  },
  wystawy: {
    list: "/adm/dashboard/wystawy",
    add: "/adm/dashboard/wystawy/add",
    edit: "/adm/dashboard/wystawy/edit",
  },
  katalogPrac: {
    list: "/adm/dashboard/katalog-prac",
    add: "/adm/dashboard/katalog-prac/add",
    edit: "/adm/dashboard/katalog-prac/edit",
  },
  sprzedaz: {
    list: "/adm/dashboard/sprzedaz",
    add: "/adm/dashboard/sprzedaz/add",
    edit: "/adm/dashboard/sprzedaz/edit",
  },
  omnie: {
    list: "/adm/dashboard/o-mnie",
    edit: "/adm/dashboard/o-mnie/edit",
  },
  kontakt: {
    index: "/adm/dashboard/kontakt",
  },
  fileManager: {
    index: "/adm/dashboard/file-manager",
  },
};
