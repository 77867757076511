import { useEffect, useState } from "react";
import { useLanguage } from "../../context/useLanguage";
import facebook from "../../Images/IconLogo/facebook-white-icon.png";
import linkedin from "../../Images/IconLogo/linkedin-logo.png";
import flaga_eng from "../../Images/IconLogo/flaga_eng.jpg";
import flaga_pl from "../../Images/IconLogo/flaga_pl.jpg";
import { fetchSocialLinks } from "../../services/social-links.service";
import { LanguageButton } from "./LanguageButton";

export const SocialIconItem = () => {
  const [links, setLinks] = useState({
    linkedin: "",
    facebook: "",
  });

  useEffect(() => {
    fetchSocialLinks().then((links) => setLinks(links));
  }, []);

  const { setLanguage } = useLanguage();

  return (
    <div>
      <a target="_blank" href={links.linkedin}>
        <img width="25" height="25" alt="linkedin-icon" src={linkedin} />
      </a>
      <a target="_blank" href={links.facebook}>
        <img width="25" height="25" alt="linkedin-icon" src={facebook} />
      </a>
      {/* language */}
      <LanguageButton onClick={() => setLanguage("eng")} src={flaga_eng}/> 
      <LanguageButton onClick={() => setLanguage("pl")} src={flaga_pl}/>
    </div>
  );
};
