import React, { Fragment, useEffect, useState, useContext } from "react";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { Button } from "../components/shared/Button";
import { ContentScreenLayout } from "../components/shared/ContentScreenLayout";
import { FluideContainer } from "../components/shared/FluideContainer";
import { ProductCard } from "../components/shared/ProductCard";
import { WystawyImageCard } from "../components/shared/WystawyImageCard";
import { WystawyModalContainer } from "../containers/WystawyModalContainer";
import { ModalContext } from "../context/ModalContext";
import { KatalogModalContainer } from "../containers/KatalogModalContainer";
import { GalleryModal } from "../containers/GalleryModal";
import { Card } from "../components/shared/Card";
import { url } from "../app/endpoints";
import { routes } from "../routes";
import { useHistory } from "react-router-dom";
import Slider from "../components/slider/Slider";

import { Helmet } from "react-helmet";
import { useLanguage } from "../context/useLanguage";

import * as homeService from "../services/home.service";
import * as katalogService from "../services/katalog-prac.service";
import * as wystawyService from "../services/wystawy.service";
import * as seoService from "../services/seo.service";

export const HomeScreen = () => {
  const { language, isEnglish } = useLanguage();
  const { modal } = useContext(ModalContext);
  const history = useHistory();

  const [pageInfo, setPageInfo] = useState({
    gallery: null,
    latestWorks: null,
    portfolio: null,
  });
  const [images, setImages] = useState([]);
  const [latestWorks, setLatestWorks] = useState([]);
  const [wystawy, setWystawy] = useState([]);
  const [seo, setSEO] = useState({
    title: null,
    description: null,
  });

  const fetchScreenData = () => {
    homeService.fetchSliderImages().then((items) => setImages(items));
    homeService.fetchHomeInfo().then((info) => setPageInfo(info));
    katalogService
      .fetchLatestKatalogPrac()
      .then((items) => setLatestWorks(items));
    wystawyService.fetchWystawy().then((items) => setWystawy(items));
    seoService.fetchSEO("home").then((data) => setSEO(data));
  };

  useEffect(() => {
    fetchScreenData();
  }, []);

  useEffect(() => {
    fetchScreenData();
  }, [language]);

  const renderLatestWorks = () =>
    latestWorks.length ? (
      <>
        {latestWorks.map((work, idx) => (
          <ProductCard
            key={idx}
            {...work}
            onClick={() => modal.show(<KatalogModalContainer {...work} />)}
          />
        ))}
      </>
    ) : null;

  const renderWystawyGrid = () => {
    const primaryCard = [...wystawy][0];
    const grid = wystawy.filter((item) => item.id !== primaryCard.id);

    return (
      <>
        <div className="primary-image">
          <Card
            {...primaryCard}
            onClick={() =>
              modal.show(<WystawyModalContainer {...primaryCard} />)
            }
          >
            <Card.Image {...primaryCard.image} />
          </Card>
        </div>
        <div className="wystawy-grid">
          {grid.map((item, idx) => (
            <WystawyImageCard
              key={idx}
              {...item}
              onClick={() => modal.show(<WystawyModalContainer {...item} />)}
            />
          ))}
        </div>
      </>
    );
  };

  return [
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
    </Helmet>,
    <Fragment>
      <Header />
      {images.length ? <Slider images={images} /> : null}
      <ContentScreenLayout>
        <div className="flex-gallery">
          <div className="gallery-description">
            <h2>
              {isEnglish()
                ? pageInfo.portfolio?.title_eng
                : pageInfo.portfolio?.title_pl}
            </h2>
            <p>
              {isEnglish()
                ? pageInfo.portfolio?.description_eng
                : pageInfo.portfolio?.description_pl}
            </p>
            <div className="gallery-buttons-area">
              <Button
                title={isEnglish() ? "See more" : "Zobać więcej"}
                onClick={() => history.push(routes.portfolio)}
              />
            </div>
          </div>
          <div className="img-container">
            <img
              style={{
                width: 380,
              }}
              src={url(pageInfo.gallery?.cover.src)}
              alt={pageInfo.gallery?.cover.alt}
            />
          </div>
        </div>
      </ContentScreenLayout>
      <ContentScreenLayout>
        <div className="gray-bg space-25">
          <div className="new-items-grid">
            {renderLatestWorks()}
            <div className="new-items-description">
              <h2>
                {isEnglish()
                  ? pageInfo.latestWorks?.title_eng
                  : pageInfo.latestWorks?.title_pl}
              </h2>
              <p>
                {isEnglish()
                  ? pageInfo.latestWorks?.description_eng
                  : pageInfo.latestWorks?.description_pl}
              </p>
            </div>
          </div>
        </div>
      </ContentScreenLayout>
      <FluideContainer>
        <div className="title-liner">
          <p> {isEnglish() ? "Exhibitions" : "Wystawy"} </p>
        </div>
      </FluideContainer>
      <ContentScreenLayout>
        <div className="flex-container">
          {wystawy.length ? renderWystawyGrid() : null}
        </div>
      </ContentScreenLayout>
      <ContentScreenLayout>
        <div className="flex-gallery">
          <div className="img-container">
            <img
              style={{
                width: 380,
              }}
              src={url(pageInfo.gallery?.cover.src)}
              alt={pageInfo.gallery?.cover.alt}
            />
          </div>
          <div className="gallery-description">
            <h2>
              {isEnglish()
                ? pageInfo.gallery?.title_eng
                : pageInfo.gallery?.title_pl}
            </h2>
            <p>
              {isEnglish()
                ? pageInfo.gallery?.description_eng
                : pageInfo.gallery?.description_pl}
            </p>
            <div className="gallery-buttons-area">
              <Button
                title={isEnglish() ? "See more" : "Zobać więcej"}
                onClick={() =>
                  modal.show(<GalleryModal images={pageInfo.gallery?.images} />)
                }
              />
            </div>
          </div>
        </div>
      </ContentScreenLayout>
      <Footer />
    </Fragment>,
  ];
};
