import { useEffect, useState } from "react";
import { CircularProgressIndicator } from "../../components/CircularProgressIndicator";
import { Image } from "../ui";
import * as service from "../services/image.service";

import "../style.css";
import { Button } from "@mui/material";
import { buttons } from "../../lang/admin.strings";

export const FileManagerModal = ({ onImage }) => {
  const [images, setImages] = useState(false);
  const [checkedId, setCheckImage] = useState(null);

  useEffect(() => {
    service.fetchImages().then((data) => setImages(data.images));
  }, []);

  const renderImages = () =>
    images.map((image) => (
      <Image
        key={image.id}
        {...image}
        checked={checkedId === image.id}
        onClick={() => setCheckImage(image.id)}
      />
    ));

  return (
    <div className="fileManagerModal">
      {/* <div> place for header </div> */}
      <div style={{ maxHeight: "65vh" }} className="fileManagerModalImagesGrid">
        {images ? renderImages() : <CircularProgressIndicator />}
      </div>
      <div className="fileManagerModalFooter">
        <Button
          variant="outlined"
          onClick={() => {
            const checkedImage = images
              .filter((image) => image.id === checkedId)
              .pop();
            onImage(checkedImage);
          }}
        >
          {buttons.select}
        </Button>
      </div>
    </div>
  );
};
