import { api } from "../app/endpoints";
import { withTranslate } from "./helpers/service-helper";

const splitByParagraphs = (data) => {
  const { description } = data;

  return {
    ...data,
    description: description.split(/\r?\n/).filter((item) => item.length > 1),
  };
};

export const fetchOMnieInfo = () =>
  fetch(api.oMnie)
    .then((res) => res.json())
    .then((data) => data)
    .then(withTranslate)
    .then(splitByParagraphs);
