import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { url } from "../../../../app/endpoints";
import "../style.css";

export const Image = ({
  id,
  src,
  alt,
  checked,
  deletable,
  onEdit,
  onDelete,
  ...other
}) => {
  const classes = `fileManagerImageCard ${checked ? "checked" : ""}`;

  const renderButtons = () => (
    <div className="fileManagerImageCardEditable">
      {/* <Button variant="outlined" onClick={() => onEdit(id)}>
        <EditIcon />
      </Button> */}
      <Button variant="outlined" color="error" onClick={() => onDelete(id)}>
        <DeleteForeverIcon />
      </Button>
    </div>
  );

  return (
    <div className="fileManagerImageCardContainer">
      <div className={classes} {...other}>
        <img src={url(src)} alt={alt} />
      </div>
      {deletable && renderButtons()}
    </div>
  );
};
