import { styled } from "@mui/system";
import { IconButton, Breadcrumbs, Link, Typography } from "@mui/material";
import { ChevronLeftTwoTone } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const FlexContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "37px 0",
});

const Spacer = styled("div")({
  width: 35,
});

const renderBreadcrumbs = (breadcrumbs) => {
  const items = [...breadcrumbs];
  const last = breadcrumbs[breadcrumbs.length - 1];
  items.pop();

  return (
    <Breadcrumbs>
      {items.map((crumb) => (
        <Link key={crumb} underline="hover" color="inherit">
          {crumb}
        </Link>
      ))}
      <Typography color="text.primary">{last}</Typography>
    </Breadcrumbs>
  );
};

export const ScreenHeader = ({
  breadcrumbs,
  withBackButton,
  actions = null,
}) => {
  const history = useHistory();

  return (
    <FlexContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        {withBackButton && (
          <>
            <IconButton onClick={() => history.goBack()}>
              <ChevronLeftTwoTone />
            </IconButton>
            <Spacer />
          </>
        )}
        {renderBreadcrumbs(breadcrumbs)}
      </div>
      {actions}
    </FlexContainer>
  );
};
