import { serverEndpoints } from "./api";
import {
  buildFormData,
  withBearerHeader,
  withJsonHeader,
} from "./helper.service";

const url = serverEndpoints.kontakt;

export const fetchKontaktInfo = () =>
  fetch(url.read(), {
    method: "GET",
    headers: {
      ...withBearerHeader(),
      ...withJsonHeader,
    },
  })
    .then((res) => res.json())
    .then((json) => JSON.parse(json.info));

export const updateKontaktInfo = (data) =>
  fetch(url.update, {
    method: "POST",
    headers: withBearerHeader(),
    body: buildFormData(data),
  }).then((res) => res.json());
