import { TextField, InputAdornment, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useRequest } from "../../../../app/helpers/network";
import { fetchSeo, updateSeo } from "../../services/seo.service";
import { HorizontalSpacer } from "../../components/Spacer";
import styled from "@emotion/styled";
import { buttons } from "../../lang/admin.strings";

const FlexRow = styled.div`
  display: flex;
`;

const ErrorMessage = styled.span`
  color: #cc0000;
`;

const promise = () => fetchSeo("home");

export const SeoTab = () => {
  const { data, inProgress, errors } = useRequest(promise);
  const save = (data) => updateSeo("home", data);

  if (errors) {
    return (
      <>
        Oops Something went wrong! reason: <ErrorMessage>{errors}</ErrorMessage>
      </>
    );
  }

  return inProgress ? (
    <>loading data ...</>
  ) : (
    !!data && <Form data={data} onSubmit={save} />
  );
};

const Form = ({ data, onSubmit }) => {
  const { register, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł PL"
          label="Tytuł PL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">PL</InputAdornment>
            ),
          }}
          {...register("title_pl", { value: data.title_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł ENG"
          label="Tytuł ENG"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">ENG</InputAdornment>
            ),
          }}
          {...register("title_eng", { value: data.title_eng })}
        />
      </FlexRow>
      <br />
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis PL"
          label="Opis PL"
          multiline
          rows={8}
          {...register("description_pl", { value: data.description_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis ENG"
          label="Opis ENG"
          multiline
          rows={8}
          {...register("description_eng", { value: data.description_eng })}
        />
      </FlexRow>
      <br />
      <br />
      <Button type="submit" variant="contained" startIcon={<Save />}>
        {buttons.save}
      </Button>
    </form>
  );
};
