import React from "react";
import { url } from "../../app/endpoints";
import { GalleryCard } from "./GalleryCard";

export const PortfolioCard = ({ image, title, onClick }) => (
  <GalleryCard onClick={onClick} border>
    <GalleryCard.Image src={url(image.src)} />
    <div>
      <h3 className="card-title center">{title}</h3>
    </div>
  </GalleryCard>
);
