export const LanguageButton = ({ src, onClick }) => {
  return (
    <button
      style={{
        backgroundColor: "#2d2d2d",
        color: "#000",
        cursor: "pointer",
        outline: "none",
        border: "none",
        borderRadius: 4,
        padding: 4,
        margin: "0 5px",
        fontWeight: 700,
      }}
      onClick={onClick}
    >
    <img width="40" height="25" alt="lang" src={src} />
    </button>
  );
};
