const pl = "pl-PL";

const getSystemLanguage = () =>
  localStorage.getItem("lang") ||
  window.navigator.userLanguage ||
  window.navigator.language;

export const isEnglish = () => getSystemLanguage() !== pl;

export const translateOneItem = (data) => {
  const prefix = getSystemLanguage() === pl ? "pl" : "eng";

  return {
    ...data,
    title: data[`title_${prefix}`],
    description: data[`description_${prefix}`],
  };
};

export const language = (items) => items.map(translateOneItem);

export const withTranslate = (data) => {
  const prefix = getSystemLanguage() === pl ? "pl" : "eng";

  return {
    ...data,
    description: data[`description_${prefix}`],
  };
};
