import { api } from "../app/endpoints";
import { language } from "./helpers/service-helper";

export const fetchKatalogPrac = () =>
  fetch(api.katalogPrac)
    .then((res) => res.json())
    .then((data) => data.items)
    .then(language);

export const fetchLatestKatalogPrac = () =>
  fetch(api.katalogPracLatest)
    .then((res) => res.json())
    .then((data) => data.items)
    .then(language);
