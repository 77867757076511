import { Box, Tabs, Tab } from "@mui/material";
import { AdminLayout } from "../components/AdminLayout";
import { useState } from "react";
import { ScreenHeader } from "../components/ScreenHeader";
import { GalleryTab } from "./tabs/GalleryTab";
import { LatestWorksTab } from "./tabs/LatestWorksTab";
import { SocialLinksTab } from "./tabs/SocialLinksTab";
import { SliderTab } from "./tabs/SliderTab";
import { PortfolioTab } from "./tabs/PortfolioTab";
import { SeoTab } from "./tabs/SeoTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const Dashboard = () => {
  const [activeTabIndex, setActiveTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <AdminLayout>
      <div>
        <ScreenHeader breadcrumbs={["Strona Główna", "Zarządzanie"]} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Slider" value={1} />
            <Tab label="Gallery" value={2} />
            <Tab label="Najnowsze prace" value={3} />
            <Tab label="Portfolio" value={4} />
            <Tab label="Social Links" value={5} />
            <Tab label="SEO" value={6} />
          </Tabs>
        </Box>

        <TabPanel value={activeTabIndex} index={1}>
          <SliderTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          <GalleryTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={3}>
          <LatestWorksTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={4}>
          <PortfolioTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={5}>
          <SocialLinksTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={6}>
          <SeoTab />
        </TabPanel>
      </div>
    </AdminLayout>
  );
};
