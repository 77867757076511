import { api } from "../app/endpoints";

export const fetchContactInfo = () =>
  fetch(api.kontakt)
    .then((res) => res.json())
    .then((data) => JSON.parse(data.info));

export const sendEmail = (data) =>
  fetch(api.sendEmail, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
