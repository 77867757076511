const DEV_ENV = false;

const DEV_SERVER = "http://localhost:8000";
const PROD_SERVER = "https://isidesign.pl";

export const server = DEV_ENV ? DEV_SERVER : PROD_SERVER;

export const url = (uri) => server + "/" + uri;

export const endpoints = {
  auth: `${server}/api/v1/authenticate`,
  signOut: `${server}/api/v1/signout`,
  validateToken: `${server}/api/v1/validate-token`,
};

export const api = {
  wystawy: `${server}/server/wystawy`,
  portfolio: `${server}/server/portfolio`,
  katalogPrac: `${server}/server/katalog-prac`,
  katalogPracLatest: `${server}/server/katalog-prac/latest`,
  sprzedaz: `${server}/server/sprzedaz`,
  oMnie: `${server}/server/o-mnie`,
  kontakt: `${server}/server/kontakt`,
  sendEmail: `${server}/server/kontakt/send`,
  slider: `${server}/server/slider`,
  home: `${server}/server/home`,
  socialLinks: `${server}/server/social-links`,
  seo: (name) => `${server}/server/seo/${name}`,
};
