import { useState } from "react";
import { Logo } from "./Logo";
import { Navigations } from "./Navigations";
import "../shared/css/navbar.css";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="header-backgraund Navbar">
      <div className="center">
        <Logo />
      </div>
      <div className="white-border" />
      <div className={`nav-items ${isOpen && "open"}`}>
        <Navigations />
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};
