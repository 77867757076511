import { serverEndpoints } from "./api";
import { withHeaders } from "./helper.service";

const url = serverEndpoints.seo;

export const fetchSeo = (name) =>
  fetch(url.get(name), {
    method: "GET",
    headers: withHeaders(),
  }).then((res) => res.json());

export const updateSeo = (name, data) =>
  fetch(url.update, {
    method: "POST",
    headers: withHeaders(),
    body: JSON.stringify({
      name,
      ...data,
    }),
  }).then((res) => res.json());
