import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XForm } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/katalog-prac.service";

export const CreateKatalogPracScreen = () => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "Katalog prac", "Stwórz"]}
        />

        <XForm
          onSubmit={(data) => {
            service.createKatalogPracItem(data).then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
