import { useState } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useRequest } from "../../../../app/helpers/network";
import { url } from "../../../../app/endpoints";
import { useModal } from "../../../../context/useModal";
import { FileManagerModal } from "../../FileManager/modals/FileManagerModal";
import {
  fetchGalleryBlockInfo,
  updateGalleryBlockInfo,
} from "../../services/gallery.service";
import styled from "@emotion/styled";
import { HorizontalSpacer } from "../../components/Spacer";
import { buttons } from "../../lang/admin.strings";

const FlexRow = styled.div`
  display: flex;
`;

const ImageCard = ({ image, deletable, onClick, onRemove, width }) => {
  return (
    <div>
      <div
        onClick={() => onClick(image)}
        style={{
          width,
          border: "1px solid #2d2d2d",
          borderRadius: 4,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "100%" }} src={url(image.src)} alt={image.alt} />
      </div>
      {deletable && (
        <div>
          <button onClick={() => onRemove(image)}>{buttons.delete}</button>
        </div>
      )}
    </div>
  );
};

const AddImageButton = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 80,
      height: 80,
      borderRadius: 4,
      border: "1px solid #2d2d2d",
      cursor: "pointer",
    }}
  >
    <p>+</p>
  </div>
);

const GalleryForm = ({ data, onSubmit }) => {
  const modal = useModal();
  const [cover, setCover] = useState(data.cover);
  const [gallery, setGallery] = useState(data.images);

  const updateCover = (image) => {
    setCover(image);
    modal.hide();
  };

  const updateGallery = (oldImage, newImage) => {
    const newList = [...gallery].map((item) => {
      if (item.id === oldImage.id) {
        return newImage;
      }

      return item;
    });
    setGallery(newList);
    modal.hide();
  };

  const addToGallery = (newImage) => {
    setGallery([...gallery, newImage]);
    modal.hide();
  };

  const removeFromGallery = (image) =>
    setGallery(gallery.filter((item) => item.id !== image.id));

  const { register, handleSubmit } = useForm();

  const prepareForm = (data) =>
    onSubmit({
      ...data,
      cover: cover.id,
      images: gallery.map((item) => item.id),
    });

  return (
    <form onSubmit={handleSubmit(prepareForm)}>
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł PL"
          label="Tytuł PL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">PL</InputAdornment>
            ),
          }}
          {...register("title_pl", { value: data.title_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Tytuł ENG"
          label="Tytuł ENG"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">ENG</InputAdornment>
            ),
          }}
          {...register("title_eng", { value: data.title_eng })}
        />
      </FlexRow>
      <br />
      <FlexRow>
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis PL"
          label="Opis PL"
          multiline
          rows={8}
          {...register("description_pl", { value: data.description_pl })}
        />
        <HorizontalSpacer />
        <TextField
          sx={{ width: "100%" }}
          placeholder="Opis ENG"
          label="Opis ENG"
          multiline
          rows={8}
          {...register("description_eng", { value: data.description_eng })}
        />
      </FlexRow>
      <br />
      <br />
      <FlexRow>
        <ImageCard
          image={cover}
          width={320}
          onClick={(_) =>
            modal.show(<FileManagerModal onImage={updateCover} />)
          }
        />
      </FlexRow>
      <br />
      <br />
      <FlexRow>
        <div style={{paddingRight: 15}}>Obrazy Galeria: </div>
        {gallery.length &&
          gallery.map((image) => (
            <ImageCard
              deletable
              key={image.id}
              image={image}
              width={145}
              onRemove={removeFromGallery}
              onClick={(oldImage) =>
                modal.show(
                  <FileManagerModal
                    onImage={(newImage) => updateGallery(oldImage, newImage)}
                  />
                )
              }
            />
          ))}
        <div>
          <AddImageButton
            onClick={(_) =>
              modal.show(<FileManagerModal onImage={addToGallery} />)
            }
          />
        </div>
      </FlexRow>
      <br />
      <br />
      <Button type="submit" variant="contained" startIcon={<Save />}>
      {buttons.save}
      </Button>
    </form>
  );
};

export const GalleryTab = () => {
  const { data, inProgress, errors } = useRequest(fetchGalleryBlockInfo);

  const saveGallery = (data) => updateGalleryBlockInfo(data);

  if (errors) {
    return <>Oops Something went wrong! reason: {errors}</>;
  }

  return inProgress ? (
    <>ładowanie danych ..</>
  ) : (
    !!data && <GalleryForm data={data} onSubmit={saveGallery} />
  );
};
