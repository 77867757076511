import { url } from "../app/endpoints";
import { Flex } from "../components/ui";

export const KatalogModalContainer = ({ image, title, description }) => {
  return (
    <Flex aligin="center">
      <div style={styles.imageContainer}>
        <img
          style={{ width: "100%", maxHeight: "70vh" }}
          src={url(image.src)}
          alt={image.alt || ""}
        />
      </div>
      <div>
        <h3>{title}</h3>
        <div style={{ height: "30px" }} />
        <p>{description}</p>
      </div>
    </Flex>
  );
};

const styles = {
  imageContainer: {
    marginRight: "22px",
  },
};
