import { server } from "../../../../app/endpoints";
import {
  withBearerHeader,
  withJsonHeader,
} from "../../services/helper.service";

const url = `${server}/api/v1/server/`;
const uri = (...parts) => `${url}${parts.join("/")}`;

const prepareImagesFormData = (list) => {
  const form = new FormData();
  Object.entries(list).forEach(([_, image]) => {
    form.append("images[]", image);
  });

  return form;
};

const prepareImageFormData = (image) => {
  const form = new FormData();
  form.append("image", image);
  form.append("alt", "");

  return form;
};

export const fetchImage = (id) =>
  fetch(uri("file", id), {
    method: "GET",
    headers: withBearerHeader(),
  }).then((res) => res.json());

export const fetchImages = () =>
  fetch(uri("files"), {
    method: "GET",
    headers: withBearerHeader(),
  }).then((res) => res.json());

export const uploadImages = (imagesList) =>
  fetch(uri("file", "upload"), {
    method: "POST",
    headers: withBearerHeader(),
    body: prepareImagesFormData(imagesList),
  }).then((res) => res.json());

export const uploadImagesInParalel = (imagesList) => {
  const promises = Object.entries(imagesList).map(([_, item]) =>
    fetch(uri("file", "create"), {
      method: "POST",
      headers: withBearerHeader(),
      body: prepareImageFormData(item),
    }).then((res) => res.json())
  );

  return Promise.all(promises);
};

export const deleteImage = (id) =>
  fetch(uri("file", "delete"), {
    method: "DELETE",
    headers: {
      ...withBearerHeader(),
      ...withJsonHeader(),
    },
    body: JSON.stringify({ fileId: id }),
  }).then((res) => res.json());
