import { AdminLayout } from "../components/AdminLayout";
import { ScreenHeader } from "../components/ScreenHeader";
import { TextField, InputAdornment, Button } from "@mui/material";
import { Image, Save } from "@mui/icons-material";
import { useState } from "react";
import { useModal } from "../../../context/useModal";
import { useForm } from "react-hook-form";
import { FileManagerModal } from "../FileManager/modals/FileManagerModal";
import { createSlider } from "../services/slider.service";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { HorizontalSpacer } from "../components/Spacer";
import { buttons } from "../lang/admin.strings";

const FlexRow = styled.div`
  display: flex;
`;

export const AddNewSlider = () => {
  const modal = useModal();
  const history = useHistory();

  const [imageId, setImageId] = useState(1);
  const { register, handleSubmit } = useForm();

  const addImage = (image) => {
    setImageId(image.id);
    modal.hide();
  };

  const submitForm = (data) => {
    createSlider({
      ...data,
      image_id: imageId,
    }).then((_) => history.goBack());
  };

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "Slider", "Dodaj suwak"]}
        />

        <form onSubmit={handleSubmit(submitForm)}>
          <FlexRow>
            <TextField
              sx={{ width: "100%" }}
              placeholder="Tytuł PL"
              label="Tytuł PL"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">PL</InputAdornment>
                ),
              }}
              {...register("title_pl")}
            />
            <HorizontalSpacer />
            <TextField
              sx={{ width: "100%" }}
              placeholder="Tytuł ENG"
              label="Tytuł ENG"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ENG</InputAdornment>
                ),
              }}
              {...register("title_eng")}
            />
          </FlexRow>
          <br />
          <FlexRow>
            <TextField
              sx={{ width: "100%" }}
              placeholder="Opis PL"
              label="Opis PL"
              multiline
              rows={8}
              {...register("description_pl")}
            />
            <HorizontalSpacer />
            <TextField
              sx={{ width: "100%" }}
              placeholder="Opis ENG"
              label="Opis ENG"
              multiline
              rows={8}
              {...register("description_eng")}
            />
          </FlexRow>
          <br />
          <br />
          <FlexRow>
            <Button
              variant="outlined"
              startIcon={<Image />}
              onClick={(_) =>
                modal.show(<FileManagerModal onImage={addImage} />)
              }
            >
             {buttons.select}
            </Button>
          </FlexRow>
          <br />
          <br />
          <Button type="submit" variant="contained" startIcon={<Save />}>
          {buttons.save}
          </Button>
        </form>
      </>
    </AdminLayout>
  );
};
