import { useHistory } from "react-router-dom";
import { AdminLayout } from "../components/AdminLayout";
import { XForm } from "../components/Form";
import { ScreenHeader } from "../components/ScreenHeader";

import * as service from "../services/portfolio.service";

export const CreatePortfolioScreen = () => {
  const history = useHistory();

  return (
    <AdminLayout>
      <>
        <ScreenHeader
          withBackButton
          breadcrumbs={["Panel CMS", "Portfolio", "Utworzyć nowe "]}
        />

        <XForm
          onSubmit={(data) => {
            service.createPortfolioItem(data).then((_) => history.goBack());
          }}
        />
      </>
    </AdminLayout>
  );
};
