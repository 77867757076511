import React from "react";
import { Button } from "./Button";
import { Card } from "./Card";

export const Sprzedaz = ({ image, title, description, onClick }) => (
  <Card border>
    <Card.Image src={image.src} />
    <div>
      <div>
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
      <div className="card-buttons-area">
        <Button title="Kup w sklepie" onClick={onClick} />
      </div>
    </div>
  </Card>
);
