export const InputField = ({ errors, onChange, ...other }) => (
  <div className="contact-input-feild">
    <input
      type="text"
      className="form-control"
      onChange={onChange}
      {...other}
    />
    {Boolean(errors) && <div style={{ color: "red" }}>{errors}</div>}
  </div>
);
